import { extractException, getEmail } from '../../../../helpers/utils';
import Cookies from 'js-cookie';
import { message } from 'antd';
import { encrypt } from 'helpers/encrypt-pwd';

const PROFILE_DROPDOWN_DATA = 'profile/PROFILE_DROPDOWN_DATA';
const PROFILE_DROPDOWN_DATA_SUCCESS = 'profile/PROFILE_DROPDOWN_DATA_SUCCESS';
const PROFILE_DROPDOWN_DATA_FAIL = 'profile/PROFILE_DROPDOWN_DATA_FAIL';
const USER_DETAIL = 'profile/USER_DETAIL';
const USER_DETAIL_SUCCESS = 'profile/USER_DETAIL_SUCCESS';
const USER_DETAIL_FAIL = 'profile/USER_DETAIL_FAIL';
const PROFILE_UPDATE = 'profile/PROFILE_UPDATE';
const PROFILE_UPDATE_SUCCESS = 'profile/PROFILE_UPDATE_SUCCESS';
const PROFILE_UPDATE_FAIL = 'profile/PROFILE_UPDATE_FAIL';
const PASSWORD_CHANGE = 'profile/PASSWORD_CHANGE';
const PASSWORD_CHANGE_SUCCESS = 'profile/PASSWORD_CHANGE_SUCCESS';
const PASSWORD_CHANGE_FAIL = 'profile/PASSWORD_CHANGE_FAIL';
const USER_LANG_TRANSLATIONS_LIST_REQUEST =
  'profile/USER_LANG_TRANSLATIONS_LIST_REQUEST';
const USER_LANG_TRANSLATIONS_LIST_REQUEST_SUCCESS =
  'profile/USER_LANG_TRANSLATIONS_LIST_REQUEST_SUCCESS';
const USER_LANG_TRANSLATIONS_LIST_REQUEST_FAIL =
  'profile/USER_LANG_TRANSLATIONS_LIST_REQUEST_FAIL';

const initialState = {
  passwordChanged: false,
  dropDownDataLoading: false,
  dropDownDataLoaded: false,
  profileUpdating: false,
  profileUpdated: false,
  userDetailLoaded: false,
  dropDownData: [],
  userDetails: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case PROFILE_DROPDOWN_DATA:
      return { ...state, dropDownDataLoading: true };
    case PROFILE_DROPDOWN_DATA_SUCCESS:
      return {
        ...state,
        dropDownDataLoaded: true,
        dropDownDataLoading: false,
        dropDownData: action.payload,
        error: null,
      };
    case PROFILE_DROPDOWN_DATA_FAIL:
      return { ...state, dropDownDataLoading: false, error: action.payload };
    case USER_DETAIL:
      return { ...state, userDetailLoading: true };
    case USER_DETAIL_SUCCESS:
      return {
        ...state,
        userDetailLoaded: true,
        userDetailLoading: false,
        userDetails: action.payload,
        error: null,
      };
    case USER_DETAIL_FAIL:
      return { ...state, userDetailLoading: false, error: action.payload };
    case PROFILE_UPDATE:
      return { ...state, profileUpdating: true };
    case PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        profileUpdating: false,
        profileUpdated: true,
      };
    case PROFILE_UPDATE_FAIL:
      return { ...state, profileUpdating: false };
    case PASSWORD_CHANGE:
      return { ...state, passwordChanging: true };
    case PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        passwordChanged: true,
        passwordChanging: false,
        passwdChangeStatus: action.payload,
      };
    case PASSWORD_CHANGE_FAIL:
      return {
        ...state,
        passwdChangeStatus: action.payload,
        passwordChanging: false,
      };
    case USER_LANG_TRANSLATIONS_LIST_REQUEST:
      return {
        ...state,
        translationsLoading: true,
        translationsLoaded: false,
      };
    case USER_LANG_TRANSLATIONS_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        translationsLoading: false,
        translationsLoaded: true,
        translationsList: action.payload,
      };
    case USER_LANG_TRANSLATIONS_LIST_REQUEST_FAIL:
      return {
        ...state,
        translationsLoading: false,
        translationsLoaded: false,
        errors: action.payload,
      };
    default:
      return state;
  }
}

export function profileDropdownData() {
  return (dispatch, getState, { api, formatParams }) => {
    const authorized = Object.keys(
      getState().authorization.initAuthorization
    ).length;
    if (authorized) {
      dispatch({ type: PROFILE_DROPDOWN_DATA });
      return api
        .post(
          'ums',
          formatParams(
            {
              type: 'PROFILE_DROPDOWN_DATA',
            },
            getState
          )
        )
        .then((response) => {
          const { data } = response;
          if (data) {
            dispatch({ type: PROFILE_DROPDOWN_DATA_SUCCESS, payload: data });
          } else {
            dispatch({
              type: PROFILE_DROPDOWN_DATA_FAIL,
              payload: 'Error in fetching dropdown data',
            });
          }
          return response;
        });
    }
  };
}

export function getUserDetails() {
  return (dispatch, getState, { api, formatParams }) => {
    const authorized = Object.keys(
      getState().authorization.initAuthorization
    ).length;
    if (authorized) {
      dispatch({ type: USER_DETAIL });
      return api
        .post(
          'ums',
          formatParams(
            {
              type: 'VIEW_SPECIFIC_USER',
              details: {
                email: getEmail(),
              },
            },
            getState
          )
        )
        .then((response) => {
          const data = response.data.result
            ? response.data.result.userdetails[0]
            : [];
          if (data) {
            dispatch({ type: USER_DETAIL_SUCCESS, payload: data });
          } else {
            dispatch({
              type: USER_DETAIL_FAIL,
              payload: 'Error in fetching user details',
            });
          }
          return response;
        });
    }
  };
}

export function updateProfile(values) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: PROFILE_UPDATE });
    return api
      .post(
        'ums',
        formatParams(
          {
            type: 'UPDATE_USER_REQUEST',
            details: values,
          },
          getState
        )
      )
      .then((response) => {
        let data = response.data.result;
        if (response.data) {
          if (response.data.returnCode == 0) {
            message.success(data.updateUserStatus);
            dispatch({
              type: PROFILE_UPDATE_SUCCESS,
              payload: data.updateUserStatus,
            });

            dispatch(getUserDetails());
          } else {
            dispatch({
              type: PROFILE_UPDATE_FAIL,
              payload: extractException(response.data.result.errorMessage),
            });
          }
        } else {
          dispatch({
            type: PROFILE_UPDATE_FAIL,
            payload: 'No response received!',
          });
        }
        return response;
      });
  };
}

export function changePasswd(values) {
  return async (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: PASSWORD_CHANGE });
    const oldPwd = await encrypt(values.old_passwd);
    const newPwd = await encrypt(values.new_passwd);
    return api
      .post(
        'ums',
        formatParams(
          {
            type: 'CHANGE_PASSWORD_REQUEST',
            details: {
              email: Cookies.get('email'),
              accessToken: Cookies.get('accessToken'),
              previousPassword: oldPwd,
              proposedPassword: newPwd,
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data.returnCode == 0) {
          const {
            result: { changePasswordStatus },
          } = data;
          dispatch({
            type: PASSWORD_CHANGE_SUCCESS,
            payload: changePasswordStatus,
          });
        } else {
          const {
            result: { errorMessage = '' },
          } = data;
          dispatch({
            type: PASSWORD_CHANGE_FAIL,
            payload: extractException(errorMessage),
          });
        }
        return response;
      });
  };
}

export function getLangTranslationList() {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: USER_LANG_TRANSLATIONS_LIST_REQUEST });
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'LANG_TRANSLATIONS_LIST',
            details: {
              email: Cookies.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data.statusCode == 200) {
          dispatch({
            type: USER_LANG_TRANSLATIONS_LIST_REQUEST_SUCCESS,
            payload: data.result,
          });
        } else {
          const {
            result: { errorMessage = '' },
          } = data;
          dispatch({
            type: USER_LANG_TRANSLATIONS_LIST_REQUEST_FAIL,
            payload: extractException(errorMessage),
          });
        }
        return response;
      });
  };
}
