import { Row, Col, Typography, Card, Divider } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const { Text } = Typography;

const RightPanel = (props) => {
  return (
    <Card className='helpdesk-card'>
      {props.content?.length
        ? props.content.map(
            ({
              contactRegion,
              ContactName,
              mailto = '',
              subject = '',
              contactDetails,
            }) => (
              <>
                <Divider
                  className='helpdesk-card-right-divider'
                  orientation='left'>
                  <div style={{ fontSize: '15px', fontWeight: 'bold' }}>
                    {' '}
                    {contactRegion}
                  </div>
                </Divider>
                <Row>
                  <Col span={contactDetails == '' ? 24 : 12}>
                    {mailto !== '' ? (
                      <Link to={`mailto:${mailto}?body=${subject}`}>
                        <Text type='secondary' style={{ color: '#74b9ff' }}>
                          {ContactName}
                        </Text>
                      </Link>
                    ) : (
                      <Text type='secondary' style={{ color: '#91d5ff' }}>
                        {ContactName}
                      </Text>
                    )}
                  </Col>
                  {contactDetails !== '' && (
                    <Col span={12}>
                      <Text type='secondary'>{contactDetails}</Text>
                    </Col>
                  )}
                </Row>
              </>
            )
          )
        : null}
      <br></br>
      <Row>
        <Col span={24}>
          {props.supportDocs &&
            props.selectedModule !== '' &&
            props.supportDocs[props.selectedModule] != undefined &&
            props.supportDocs[props.selectedModule][0].url != undefined && (
              <a
                href={props.supportDocs[props.selectedModule][0].url}
                target='_blank'>
                <Text type='secondary' style={{ color: '#74b9ff' }}>
                  {props.supportDocs[props.selectedModule][0].title}
                </Text>
              </a>
            )}
        </Col>
      </Row>
    </Card>
  );
};

RightPanel.propTypes = {
  content: PropTypes.array,
};

export default RightPanel;
