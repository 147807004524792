/** @jsxRuntime classic */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

/**
 * This function adds one to its input.
 * @param {number} input any number
 * @returns {number} that number, plus one.
 */
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { setAgGridLicense, getAgGridEnterPriseModule } from './helpers/utils';
import './modules/common/polyfills/prepend';

//import ag-grid enterprise module
getAgGridEnterPriseModule();

import './ag-grid-overrides';

// Setting Ag Grid License
setAgGridLicense();

createRoot(document.getElementById('root')).render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
