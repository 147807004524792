import { message } from 'antd';
import { isObject, sortArrayList, filterSuppliersList } from 'helpers/utils';
import Cookie from 'js-cookie';
import {
  getSupplierModFlag,
  filterSupplierDetailsByKey,
  filterSupplierDetails,
  getEmail,
} from '../../../../helpers/utils';
export const PC_SUPPLIERS_LIST = 'quick-search/PC_SUPPLIERS_LIST';
export const PC_SUPPLIERS_LIST_SUCCESS =
  'quick-search/PC_SUPPLIERS_LIST_SUCCESS';
export const PC_SUPPLIERS_LIST_ERROR = 'quick-search/PC_SUPPLIERS_LIST_ERROR';
export const PC_PLANTS_LIST = 'quick-search/PC_PLANTS_LIST';
export const PC_PLANTS_LIST_SUCCESS = 'quick-search/PC_PLANTS_LIST_SUCCESS';
export const PC_PLANTS_LIST_ERROR = 'quick-search/PC_PLANTS_LIST_ERROR';
export const PC_SELECTED_DATA = 'quick-search/PC_SELECTED_DATA';
export const PC_QS_BULK_LIST_UPDATE_SUCCESS =
  'quick-search/PC_QS_BULK_LIST_UPDATE_SUCCESS';
export const PC_QS_LIST_NAVIGATION = 'quick-search/PC_QS_LIST_NAVIGATION';
export const PC_SAVED_SEARCH_LIST = 'saved-search/PC_SAVED_SEARCH_LIST';
export const PC_SAVED_SEARCH_LIST_SUCCESS =
  'saved-search/PC_SAVED_SEARCH_LIST_SUCCESS';
export const PC_SAVED_SEARCH_LIST_ERROR =
  'saved-search/PC_SAVED_SEARCH_LIST_ERROR';
export const PC_SS_DETAILS = 'saved-search/PC_SS_DETAILS';
export const PC_SS_DETAILS_SUCCESS = 'saved-search/PC_SS_DETAILS_SUCCESS';
export const PC_SS_DETAILS_ERROR = 'saved-search/PC_SS_DETAILS_ERROR';
export const PC_SS_DELETE_UPDATE = 'saved-search/PC_SS_DELETE_UPDATE';
export const PC_RESET_SEARCH = 'search/PC_RESET_SEARCH';
export const PC_SAVED_SEARCH_CREATE = 'saved-search/PC_SAVED_SEARCH_CREATE';
export const PC_SAVED_SEARCH_CREATE_SUCCESS =
  'saved-search/PC_SAVED_SEARCH_CREATE_SUCCESS';
export const PC_SAVED_SEARCH_CREATE_ERROR =
  'saved-search/PC_SAVED_SEARCH_CREATE_ERROR';

export const PC_QS_MODAL_APPLIED = 'quick-search/PC_QS_MODAL_APPLIED';
export const PC_QS_APPLIED = 'quick-search/PC_QS_APPLIED';
export const PC_QS_PLANT_APPLIED = 'quick-search/PC_QS_PLANT_APPLIED';
export const PC_QS_OTHERS_APPLIED = 'quick-search/PC_QS_OTHERS_APPLIED';
export const PC_QS_ADD_VIEW_TYPE = 'quick-search/PC_QS_ADD_VIEW_TYPE';
export const PC_QS_RESET_PART_DETAILS = 'quick-search/PC_QS_RESET_PART_DETAILS';

export const PC_CLOSE_GLOBAL_SEARCH = 'global-search/PC_CLOSE_GLOBAL_SEARCH';
export const PC_LOADSEARCH = 'load-search/PC_LOADSEARCH';

export const PC_GLOBAL_SEARCH = 'global-search/PC_GLOBAL_SEARCH';
export const PC_QS_UPDATE_SUCCESS = 'quick-search/PC_QS_UPDATE_SUCCESS';
export const PC_UNSAVED_QS_UPDATE = 'quick-search/PC_UNSAVED_QS_UPDATE';

export const PC_SELECTED_PART_DATA = 'quick-search/PC_SELECTED_PART_DATA';

const initialState = {
  quickSearch: {
    dashboard: { isQsUnsaved: false },
    newTab0: { isQsUnsaved: false },
    newTab1: { isQsUnsaved: false },
    newTab2: { isQsUnsaved: false },
    newTab3: { isQsUnsaved: false },
    newTab4: { isQsUnsaved: false },
    newTab5: { isQsUnsaved: false },
  },
  savedSearch: {
    dashboard: {},
    newTab0: {},
    newTab1: {},
    newTab2: {},
    newTab3: {},
    newTab4: {},
    newTab5: {},
  },
  visibleQsandSs: {
    dashboard: { visibleQs: false, visibleSs: false },
    newTab0: { visibleQs: false, visibleSs: false },
    newTab1: { visibleQs: false, visibleSs: false },
    newTab2: { visibleQs: false, visibleSs: false },
    newTab3: { visibleQs: false, visibleSs: false },
    newTab4: { visibleQs: false, visibleSs: false },
    newTab5: { visibleQs: false, visibleSs: false },
  },
  loadSearch: false,
};

export const getPlantsList = (masterData) => {
  let plantList = new Set([]);
  if (masterData.length) {
    masterData.map((data) => plantList.add(data.customer_plant));
  }

  return plantList;
};

export default function search(state = initialState, action = {}) {
  switch (action.type) {
    case PC_SUPPLIERS_LIST:
      return {
        ...state,
        loadingSuppliers: true,
        suppliersList: [],
      };
    case PC_SUPPLIERS_LIST_SUCCESS:
      return {
        ...state,
        suppliersList: action.payload,
        loadingSuppliers: false,
      };
    case PC_SUPPLIERS_LIST_ERROR:
      return {
        ...state,
        error: action.payload,
        loadingSuppliers: false,
      };
    case PC_PLANTS_LIST:
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            loadingPlants: true,
            customerPlants: [],
            reset: false,
          },
        },
      };
    case PC_PLANTS_LIST_SUCCESS: {
      let supplModList = getSupplierModFlag(action.view);
      let dataList = action.payload;
      let suppliersList = filterSupplierDetailsByKey(
        state.suppliersList,
        'customer_plant',
        [...dataList],
        supplModList
      );

      const names = getSupplierDetailsByKey(
        suppliersList,
        action.payload,
        'customer_plant',
        'supplier_name',
        supplModList
      );

      const codes = getSupplierDetailsByKey(
        suppliersList,
        [...names],
        'supplier_name',
        'supplier_code',
        supplModList
      );

      const sites = getSupplierDetailsByKey(
        suppliersList,
        [...codes],
        'supplier_code',
        'site_name',
        supplModList
      );

      const depots = getSupplierDetailsByKey(
        suppliersList,
        [...sites],
        'site_name',
        'supplier_key',
        supplModList
      );
      if (!action.fromTab) {
        return {
          ...state,
          [action.searchType]: {
            ...state[action.searchType],
            [action.tabKey]: {
              ...state[action.searchType][action.tabKey],
              customerPlants: action.payload,
              supplierNameList: sortArrayList(names),
              supplierCodeList: sortArrayList(codes),
              supplierSiteList: sortArrayList(sites),
              supplierDepotList: sortArrayList(depots),
              loadingPlants: false,
            },
          },
        };
      } else {
        return {
          ...state,
          loadSearch: '9',
        };
      }
    }
    case PC_PLANTS_LIST_ERROR:
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            loadingPlants: false,
            plantsError: action.error,
            customerPlants: [],
          },
        },
      };
    case PC_SELECTED_DATA:
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            [action.propToUpdate]: action.payload,
            reset: false,
          },
        },
      };

    case PC_SELECTED_PART_DATA:
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            [action.propToUpdate]: action.payload,
            reset: false,
            isQsUnsaved: true,
          },
        },
      };

    case PC_QS_BULK_LIST_UPDATE_SUCCESS:
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            ...action.payload,
          },
        },
      };
    case PC_SAVED_SEARCH_LIST:
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            savedListLoading: true,
            savedList: [],
          },
        },
      };
    case PC_SAVED_SEARCH_LIST_SUCCESS:
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            savedListLoading: false,
            savedList: action.payload,
          },
        },
      };
    case PC_SAVED_SEARCH_LIST_ERROR:
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            savedListLoading: false,
          },
        },
      };
    case PC_SS_DELETE_UPDATE: {
      const {
        supplierNameList,
        supplierSiteList,
        supplierCodeList,
        supplierDepotList,
        customerPlantsList,
      } = filterSuppliersList(state['suppliersList'], [], {}, action.view);
      let quickSearchData = {
        ...state['quickSearch'][action.tabKey],
        customerPlants: customerPlantsList,
        agilityTypesList:
          state['quickSearch'][action.tabKey]['agilityTypesList'],
        supplierNameList: supplierNameList,
        supplierSiteList: supplierSiteList,
        supplierCodeList: supplierCodeList,
        supplierDepotList: supplierDepotList,
        customerPlant: [],
        supplierName: [],
        supplierCode: [],
        supplierDepot: [],
        agilityType: [],
        searchTerm: '',
        reset: true,
      };
      return {
        ...state,
        ['quickSearch']: {
          ...state['quickSearch'],
          [action.tabKey]: quickSearchData,
        },
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            savedList: state.savedSearch[action.tabKey].savedList.filter(
              (data) => data.id !== action.searchId
            ),
          },
        },
      };
    }
    case PC_RESET_SEARCH: {
      const { customerPlants, loadingPlants, qsModalApplied } =
        state[action.searchType][action.tabKey];
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            customerPlants,
            loadingPlants,
            qsModalApplied,
            reset: true,
            customerPlant: [],
            supplierName: [],
            supplierCode: [],
            supplierSite: [],
            supplierDepot: [],
            agilityType: [],
            searchTerm: '',
            isQsUnsaved: true,
          },
        },
      };
    }
    case PC_SAVED_SEARCH_CREATE:
      return {
        ...state,
        quickSearch: {
          ...state.quickSearch,
          [action.tabKey]: {
            ...state.quickSearch[action.tabKey],
            qsModalApplied: true,
            creatingSavedSearch: true,
          },
        },
      };
    case PC_SAVED_SEARCH_CREATE_SUCCESS:
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            savedList: [
              ...(state[action.searchType][action.tabKey]['savedList'] || {}),
              ...action.savedList,
            ],
          },
        },
        quickSearch: {
          ...state.quickSearch,
          [action.tabKey]: {
            qsModalApplied: true,
            creatingSavedSearch: false,
            customerPlants: action?.payload?.customerPlants,
            supplierNameList: action?.payload?.supplierNameList,
            supplierCodeList: action?.payload?.supplierCodeList,
            supplierSiteList: action?.payload?.supplierSiteList,
            supplierDepotList: action?.payload?.supplierDepotList,
            customerPlant: action?.payload?.customerPlant,
            supplierName: action?.payload?.supplierName,
            supplierCode: action?.payload?.supplierCode,
            supplierSite: action?.payload?.supplierSite,
            supplierDepot: action?.payload?.supplierDepot,
            agilityType: action?.payload?.agilityType,
            agilityTypesList: action?.payload?.agilityTypesList,
            searchTerm: action?.payload?.searchTerm,
          },
        },
      };
    case PC_SAVED_SEARCH_CREATE_ERROR:
      return {
        ...state,
        quickSearch: {
          ...state.quickSearch,
          [action.tabKey]: {
            creatingSavedSearch: false,
            error: action.payload,
          },
        },
      };
    case PC_SS_DETAILS:
      return {
        ...state,
        quickSearch: {
          ...state.quickSearch,
          [action.tabKey]: {
            customerPlants: state?.quickSearch?.[action.tabKey]?.customerPlants,
            agilityTypesList:
              state?.quickSearch?.[action.tabKey]?.agilityTypesList,
            applyingSavedSearch: true,
            qsModalApplied: true,
          },
        },
      };
    case PC_SS_DETAILS_SUCCESS:
      let customerPlantsList =
        state?.quickSearch?.[action.tabKey]?.customerPlants;
      let agilityTypesList =
        state?.quickSearch?.[action.tabKey]?.agilityTypesList;
      let supplModList = getSupplierModFlag(action.view);
      let supplierBySelectedSupplier = [];
      let supplierBySelectedSupplierCode = [];
      let dataList =
        action?.payload?.customerPlant !== undefined
          ? typeof action.payload.customerPlant === 'object'
            ? action.payload.customerPlant
            : [action.payload.customerPlant]
          : [];
      let supplNameList =
        action?.payload?.supplierName !== undefined
          ? typeof action?.payload?.supplierName === 'string'
            ? action?.payload?.supplierName !== ''
              ? [action?.payload?.supplierName]
              : []
            : action?.payload?.supplierName
          : [];
      let supplCodeList =
        action?.payload?.supplierCode !== undefined
          ? typeof action?.payload?.supplierCode === 'string'
            ? action?.payload?.supplierCode !== ''
              ? [action?.payload?.supplierCode]
              : []
            : action?.payload?.supplierCode
          : [];

      let suppliersList = filterSupplierDetails(
        state.suppliersList,
        dataList,
        'customer_plant',

        supplModList
      );

      if (supplNameList?.length > 0) {
        supplierBySelectedSupplier = filterSupplierDetails(
          suppliersList,
          supplNameList,
          'supplier_name',
          supplModList
        );
      } else {
        supplierBySelectedSupplier = suppliersList;
      }

      const name = getSupplierDetailsByKey(
        suppliersList,
        dataList,
        'customer_plant',
        'supplier_name',
        supplModList
      );

      if (supplCodeList?.length > 0) {
        supplierBySelectedSupplierCode = filterSupplierDetails(
          supplierBySelectedSupplier,
          supplCodeList,
          'supplier_code',
          supplModList
        );
      } else if (
        (supplNameList?.length > 0 && dataList?.length > 0) ||
        (supplNameList?.length > 0 && dataList?.length === 0)
      ) {
        supplierBySelectedSupplierCode = supplierBySelectedSupplier;
      } else if (supplNameList?.length === 0 && dataList?.length > 0) {
        supplierBySelectedSupplierCode = suppliersList;
      } else {
        supplierBySelectedSupplierCode = suppliersList;
      }

      const code = getSupplierDetailsByKey(
        supplierBySelectedSupplier,
        supplNameList,
        'supplier_name',
        'supplier_code',
        supplModList
      );
      const site = getSupplierDetailsByKey(
        supplierBySelectedSupplierCode,
        [...code],
        'supplier_code',
        'site_name',
        supplModList
      );
      const depot = getSupplierDetailsByKey(
        supplierBySelectedSupplierCode,
        [...site],
        'site_name',
        'supplier_key',
        supplModList
      );
      return {
        ...state,
        quickSearch: {
          ...state.quickSearch,
          [action.tabKey]: {
            applyingSavedSearch: false,
            ...action.payload,
            customerPlants: customerPlantsList,
            agilityTypesList: agilityTypesList,
            supplierNameList: name,
            supplierCodeList: code,
            supplierSiteList: site,
            supplierDepotList: depot,
            qsModalApplied: true,
            isQsUnsaved: false,
            defaultValuesApplied: true,
          },
        },
      };
    case PC_SS_DETAILS_ERROR:
      return {
        ...state,
        quickSearch: {
          ...state.quickSearch,
          [action.tabKey]: {
            applyingSavedSearch: false,
            error: action.payload,
          },
        },
      };
    case PC_QS_LIST_NAVIGATION: {
      let supplModList = getSupplierModFlag(action.view);
      let supplierBySelectedSupplier = [];
      let supplierBySelectedSupplierCode = [];
      let dataList =
        action?.screenData?.customerPlant !== undefined
          ? typeof action.screenData.customerPlant === 'object'
            ? action.screenData.customerPlant
            : [action.screenData.customerPlant]
          : [];
      let supplNameList =
        action?.screenData?.supplierName !== undefined
          ? typeof action?.screenData?.supplierName === 'string'
            ? action?.screenData?.supplierName !== ''
              ? [action?.screenData?.supplierName]
              : []
            : action?.screenData?.supplierName
          : [];
      let supplCodeList =
        action?.screenData?.supplierCode !== undefined
          ? typeof action?.screenData?.supplierCode === 'string'
            ? action?.screenData?.supplierCode !== ''
              ? [action?.screenData?.supplierCode]
              : []
            : action?.screenData?.supplierCode
          : [];

      let suppliersList = filterSupplierDetails(
        state.suppliersList,
        dataList,
        'customer_plant',

        supplModList
      );

      if (supplNameList?.length > 0) {
        supplierBySelectedSupplier = filterSupplierDetails(
          suppliersList,
          supplNameList,
          'supplier_name',
          supplModList
        );
      } else {
        supplierBySelectedSupplier = suppliersList;
      }

      const name = getSupplierDetailsByKey(
        suppliersList,
        dataList,
        'customer_plant',
        'supplier_name',
        supplModList
      );

      if (supplCodeList?.length > 0) {
        supplierBySelectedSupplierCode = filterSupplierDetails(
          supplierBySelectedSupplier,
          supplCodeList,
          'supplier_code',
          supplModList
        );
      } else if (
        (supplNameList?.length > 0 && dataList?.length > 0) ||
        (supplNameList?.length > 0 && dataList?.length === 0)
      ) {
        supplierBySelectedSupplierCode = supplierBySelectedSupplier;
      } else if (supplNameList?.length === 0 && dataList?.length > 0) {
        supplierBySelectedSupplierCode = suppliersList;
      } else {
        supplierBySelectedSupplierCode = suppliersList;
      }

      const code = getSupplierDetailsByKey(
        supplierBySelectedSupplier,
        supplNameList,
        'supplier_name',
        'supplier_code',
        supplModList
      );
      const site = getSupplierDetailsByKey(
        supplierBySelectedSupplierCode,
        [...code],
        'supplier_code',
        'site_name',
        supplModList
      );
      const depot = getSupplierDetailsByKey(
        supplierBySelectedSupplierCode,
        [...site],
        'site_name',
        'supplier_key',
        supplModList
      );

      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            ...action.screenData,
            supplierNameList: name,
            supplierCodeList: code,
            supplierSiteList: site,
            supplierDepotList: depot,
            defaultValuesApplied: true,
          },
        },
      };
    }
    case PC_QS_MODAL_APPLIED:
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            qsModalApplied: true,
            isQsUnsaved: false,
          },
        },
      };
    case PC_QS_ADD_VIEW_TYPE:
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            viewType: action.viewType,
          },
        },
      };
    case PC_QS_APPLIED:
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            qsApplied: true,
          },
        },
      };
    case PC_QS_PLANT_APPLIED:
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            customerPlant: [action.screenData.customerPlant],
            qsApplied: true,
            supplierCode: action.screenData.supplierCode,
            supplierDepot: action.screenData.supplierDepot,
            supplierName: action.screenData.supplierName,
            supplierSite: action.screenData.supplierSite,
            agilityType: action.screenData.agilityType,
            customerPlants: action.screenData.customerPlants,
            agilityTypesList: action.screenData.agilityTypesList,
            supplierNameList: action.screenData.supplierNameList,
            supplierSiteList: action.screenData.supplierSiteList,
            supplierCodeList: action.screenData.supplierCodeList,
            supplierDepotList: action.screenData.supplierDepotList,
          },
        },
      };
    case PC_QS_OTHERS_APPLIED:
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            customerPlant: [action.screenData.customerPlant],
            partName: [action.screenData.partName],
            searchTerm: action.screenData.searchTerm,
            supplierCode: [action.screenData.supplierCode],
            supplierDepot: [action.screenData.supplierDepot],
            supplierName: [action.screenData.supplierName],
            supplierSite: [action.screenData.supplierSite],
            agilityType: [action.screenData.agilityType],
            qsApplied: true,
          },
        },
      };
    case PC_QS_RESET_PART_DETAILS:
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            partDetails: [],
            partName: '',
          },
        },
      };
    case PC_CLOSE_GLOBAL_SEARCH:
      return {
        ...state,
        searchProps: {
          ...action.payload,
        },
      };
    case PC_GLOBAL_SEARCH:
      return {
        ...state,
        visibleQsandSs: {
          ...state.visibleQsandSs,
          [action.tabKey]: action.payload,
        },
      };
    case PC_QS_UPDATE_SUCCESS:
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...action.payload,
          },
        },
      };
    case PC_UNSAVED_QS_UPDATE:
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            ...action.payload,
          },
        },
      };
    case PC_LOADSEARCH:
      return {
        ...state,
        loadSearch: '0',
      };
    default:
      return state;
  }
}

function getSupplierDetails(view) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: PC_SUPPLIERS_LIST, tabKey: view });
    return api
      .post(
        'pc',
        formatParams({ type: 'PC_QS_SUPPLIER_DETAILS', view }, getState)
      )
      .then((response) => {
        const { data } = response;

        if (data) {
          dispatch({
            type: PC_SUPPLIERS_LIST_SUCCESS,
            payload: data.result,
            tabKey: view,
          });
        }
      })
      .catch((errors) =>
        dispatch({ type: PC_SUPPLIERS_LIST_ERROR, errors, tabKey: view })
      );
  };
}

function getPlantDetails(tabKey, view, searchType, screenData = {}) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: PC_PLANTS_LIST, tabKey, searchType });
    return api
      .post('pc', formatParams({ type: 'PC_QS_PLANTS', view }, getState))
      .then((response) => {
        const { data } = response;
        if (data) {
          dispatch({
            type: PC_PLANTS_LIST_SUCCESS,
            tabKey,
            payload: data.result,
            searchType,
            view: view,
          });

          if (screenData?.navigation) {
            dispatch({
              type: PC_QS_LIST_NAVIGATION,
              tabKey,
              screenData,
              searchType,
              plantsList: data.result,
              view: view,
            });

            const modulesToExclude = [
              'RTF_FIRM_RELEASE_RESPONSE',
              'RTF_RELEASE_HISTORY',
              'RTF_RELEASE_RECONCILIATION',
              'siteExecutor',
              'ASN_HISTORY',
              'ASN_SHIP_NOTICE',
              'vci_dvr',
              'VCI_SHIPMENTS',
              'VCI_SHIPMENTS_HISTORY',
              'newbuys',
              'reschedules',
            ];
            if (!modulesToExclude.includes(view)) {
              dispatch(getAgilityTypes(view, tabKey, searchType));
            }
          }
        }
      })
      .catch((errors) =>
        dispatch({ type: PC_PLANTS_LIST_ERROR, errors, tabKey, searchType })
      );
  };
}

function setSelectedData(data, tabKey, propToUpdate, searchType) {
  return (dispatch) =>
    dispatch({
      type:
        propToUpdate === 'searchTerm'
          ? PC_SELECTED_PART_DATA
          : PC_SELECTED_DATA,
      payload: data,
      tabKey,
      propToUpdate,
      searchType,
    });
}

function updateListBulk(data, tabKey, searchType) {
  return (dispatch) => {
    dispatch({
      type: PC_QS_BULK_LIST_UPDATE_SUCCESS,
      payload: data,
      tabKey,
      searchType,
    });
  };
}

const excludeAll = (d) => {
  if (typeof d === 'string') {
    return [d];
  }
  return d ? d.filter((val) => val !== 'all') : [];
};

function formatQsProps(data, camelCase = true) {
  const {
    customerPlant,
    supplierName,
    supplierCode,
    supplierSite,
    supplierDepot,
    agilityType,
    searchTerm,
  } = data;

  if (camelCase) {
    return {
      customerPlant: excludeAll(customerPlant),
      supplierName: excludeAll(supplierName),
      supplierCode: excludeAll(supplierCode),
      supplierSite: excludeAll(supplierSite),
      supplierDepot: excludeAll(supplierDepot),
      agilityType: excludeAll(agilityType),
      searchTerm: searchTerm || '',
    };
  }

  return {
    customer_plant: customerPlant ? customerPlant.join() : '',
    supplier_name: supplierName ? supplierName.join() : '',
    supplier_code: supplierCode ? supplierCode.join() : '',
    site_name: supplierSite ? supplierSite.join() : '',
    supplier_key: supplierDepot ? supplierDepot.join() : '',
    agility_type: agilityType ? agilityType.join() : '',
    search_term: searchTerm || '',
  };
}

function getPartDetails(searchTerm, view, tabKey, searchType, viewType) {
  return (dispatch, getState, { api, formatParams }) => {
    const {
      pc: { search: s },
    } = getState();
    const {
      customerPlants,
      agilityTypesList,
      supplierCodeList,
      supplierDepotList,
      supplierNameList,
      supplierSiteList,
      loadingPlants,
      reset,
      ...tabData
    } = s[searchType][tabKey] || {};
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'PC_QS_PARTS_SEARCH',
            view,
            viewType,
            ...formatQsProps({ ...tabData, searchTerm }),
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data?.result?.length) {
          let options = data.result.map((option) => ({
            label: option,
            value: option,
          }));
          dispatch(setSelectedData(options, tabKey, 'partDetails', searchType));
        } else {
          dispatch(setSelectedData([], tabKey, 'partDetails', searchType));
        }
      });
  };
}

const getAgilityTypes = (view, tabKey, searchType) => {
  return (dispatch, getState, { api, formatParams }) => {
    const {
      pc: { search: s },
    } = getState();
    const {
      customerPlants,
      agilityTypesList,
      supplierCodeList,
      supplierDepotList,
      supplierNameList,
      supplierSiteList,
      loadingPlants,
      reset,
      ...tabData
    } = s[searchType][tabKey] || {};
    return api
      .post(
        'pc',
        formatParams(
          { type: 'QS_AGILITY_TYPE', view, ...formatQsProps(tabData) },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data?.result?.length) {
          dispatch(
            setSelectedData(data.result, tabKey, 'agilityTypesList', searchType)
          );
        }
      });
  };
};

function getSavedList(view, tabKey, searchType) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: PC_SAVED_SEARCH_LIST, tabKey, searchType });
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'PC_SAVED_SEARCH_LIST',
            view,
            details: {
              email: getEmail(),
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data?.result?.length) {
          dispatch({
            type: PC_SAVED_SEARCH_LIST_SUCCESS,
            payload: data.result,
            tabKey,
            searchType,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: PC_SAVED_SEARCH_LIST_ERROR,
          payload: error,
          tabKey,
          searchType,
        });
      });
  };
}

function fetchSavedDetails(searchId, view, tabKey, searchType, callBack) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: PC_SS_DETAILS, view, tabKey, searchType });
    return api
      .post(
        'pc',
        formatParams(
          { type: 'PC_SAVED_SEARCH_DETAILS', view, searchId },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data.result && isObject(data.result)) {
          dispatch({
            type: PC_SS_DETAILS_SUCCESS,
            payload: data.result,
            tabKey,
            searchType,
            view,
          });
          callBack();
        }
      })
      .catch((error) => {
        dispatch({
          type: PC_SS_DETAILS_ERROR,
          payload: error,
          tabKey,
          searchType,
        });
      });
  };
}

function updateSavedList(searchId, tabKey, searchType, view) {
  return (dispatch) =>
    dispatch({ type: PC_SS_DELETE_UPDATE, searchId, tabKey, searchType, view });
}

function saveSearch(name, view, searchData, tabKey, searchType, t) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: PC_SAVED_SEARCH_CREATE });
    const {
      customerPlants,
      agilityTypesList,
      supplierCodeList,
      supplierDepotList,
      supplierNameList,
      supplierSiteList,
      loadingPlants,
      savedList,
      savedListLoading,
      reset,
      ...tabData
    } = searchData;
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'PC_SAVE_SEARCH',
            view,
            name,
            ...formatQsProps(tabData),
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data.statusCode == '200') {
          message.success(t('search_created_and_applied_successfully'));
          dispatch({
            type: PC_SAVED_SEARCH_CREATE_SUCCESS,
            tabKey,
            searchType,
            payload: searchData,
            savedList: [{ ...data.result, id: data.result.id.toString() }],
          });
        } else {
          dispatch({
            type: PC_SAVED_SEARCH_CREATE_ERROR,
            tabKey,
            searchType,
            payload: 'Error in creating new search. Please try again!',
          });
        }
        return response;
      })
      .catch((_error) => {
        message.error(t('something_went_wrong'));
      });
  };
}

function deleteSearch(searchId, view, callback, tabKey, searchType, t) {
  return (dispatch, getState, { api, formatParams }) => {
    return api
      .post(
        'pc',
        formatParams(
          { type: 'PC_SAVED_SEARCH_DELETE', view, searchId },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data.statusCode == '200') {
          message.success(t('search_criteria_deleted_successfully'));
          dispatch(updateSavedList(searchId, tabKey, searchType, view));
          callback(true);
        }
      })
      .catch((_error) => {
        message.error(t('something_went_wrong'));
      });
  };
}

function resetSearch(tabKey, searchType) {
  return (dispatch) => {
    dispatch({
      type: PC_RESET_SEARCH,
      tabKey,
      searchType,
    });
  };
}

const getSupplierDetailsByKey = (
  suppliersList,
  dataList,
  filterKey,
  resultKey,
  supplModList
) => {
  let values = new Set([]);
  if (dataList.length > 0) {
    dataList.map((data) => {
      suppliersList.forEach((plant) => {
        if (
          plant[filterKey] === data &&
          supplModList?.includes(plant['supplier_mod_flag'])
        ) {
          values.add(plant[resultKey]);
        }
      });
    });
  } else {
    suppliersList.forEach((plant) => {
      if (supplModList?.includes(plant['supplier_mod_flag'])) {
        values.add(plant[resultKey]);
      }
    });
  }

  let sortedValues = [...values].sort(function (a, b) {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  });

  return sortedValues;
};

const setQsModalApplied = (tabKey, searchType) => {
  return (dispatch) => {
    dispatch({ type: PC_QS_MODAL_APPLIED, tabKey, searchType });
  };
};

const setQsApplied = (tabKey, searchType) => {
  return (dispatch) => {
    dispatch({ type: PC_QS_APPLIED, tabKey, searchType });
  };
};
const setQsPlantApplied = (tabKey, searchType, screenData) => {
  return (dispatch) => {
    dispatch({ type: PC_QS_PLANT_APPLIED, tabKey, searchType, screenData });
  };
};

const setQsOthersApplied = (tabKey, searchType, screenData) => {
  return (dispatch) => {
    dispatch({ type: PC_QS_OTHERS_APPLIED, tabKey, searchType, screenData });
  };
};

const updateQsViewType = (viewType, tabKey, searchType) => {
  return (dispatch) => {
    dispatch({ type: PC_QS_ADD_VIEW_TYPE, viewType, tabKey, searchType });
  };
};

const resetPartDetails = (tabKey) => {
  return (dispatch) => {
    dispatch({
      type: PC_QS_RESET_PART_DETAILS,
      tabKey,
      searchType: 'quickSearch',
    });
  };
};

const setSearchCloseProps = (data) => {
  return (dispatch) => {
    dispatch({
      type: PC_CLOSE_GLOBAL_SEARCH,
      payload: data,
    });
  };
};

const setGlobalSearchProps = (tabKey, data) => {
  return (dispatch) => {
    dispatch({
      type: PC_GLOBAL_SEARCH,
      tabKey,
      payload: data,
    });
  };
};

function updateQSList(data, tabKey, searchType) {
  return (dispatch) => {
    dispatch({
      type: PC_QS_UPDATE_SUCCESS,
      payload: data,
      tabKey,
      searchType,
    });
  };
}

function setIsUnsavedQs(data, tabKey, searchType) {
  return (dispatch) => {
    dispatch({
      type: PC_UNSAVED_QS_UPDATE,
      payload: data,
      tabKey,
      searchType,
    });
  };
}

export {
  getSupplierDetails,
  getPlantDetails,
  setSelectedData,
  getPartDetails,
  getAgilityTypes,
  updateListBulk,
  getSavedList,
  fetchSavedDetails,
  deleteSearch,
  resetSearch,
  saveSearch,
  getSupplierDetailsByKey,
  formatQsProps,
  setQsModalApplied,
  updateQsViewType,
  setQsApplied,
  setQsPlantApplied,
  setQsOthersApplied,
  resetPartDetails,
  setSearchCloseProps,
  setGlobalSearchProps,
  updateQSList,
  setIsUnsavedQs,
};
