import { message, Row, Col, Modal } from 'antd';
import Cookie from 'js-cookie';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import store from '../../../../../redux/store';
import service from '../../../../../service/Service';
import {
  getLastRowIndex,
  getUUID,
  isObject,
  getEmail,
  gaPageviewTimingTitle,
  getEpochTime,
} from 'helpers/utils';
import { exportDTo, getMassDownloadGridParams } from 'helpers/export';
import { getFormattedDateStr } from '../../helpers/components/component-utils';

export const RESCHEDULES_LOV = 'pc/reschedules/RESCHEDULES_LOV';
export const RESCHEDULES_LOV_SUCCESS = 'pc/reschedules/RESCHEDULES_LOV_SUCCESS';
export const RESCHEDULES_LOV_ERROR = 'pc/reschedules/RESCHEDULES_LOV_ERROR';

export const RESCHEDULES_OPEN_COLUMNS =
  'pc/reschedules/RESCHEDULES_OPEN_COLUMNS';
export const RESCHEDULES_OPEN_COLUMNS_SUCCESS =
  'pc/reschedules/RESCHEDULES_OPEN_COLUMNS_SUCCESS';
export const RESCHEDULES_OPEN_COLUMNS_FAIL =
  'pc/reschedules/RESCHEDULES_OPEN_COLUMNS_FAIL';

export const RESCHEDULES_CLOSED_COLUMNS =
  'pc/reschedules/RESCHEDULES_CLOSED_COLUMNS';
export const RESCHEDULES_CLOSED_COLUMNS_SUCCESS =
  'pc/reschedules/RESCHEDULES_CLOSED_COLUMNS_SUCCESS';
export const RESCHEDULES_CLOSED_COLUMNS_FAIL =
  'pc/reschedules/RESCHEDULES_CLOSED_COLUMNS_FAIL';

export const RESCHEDULES_HISTORY_COLUMNS =
  'pc/reschedules/RESCHEDULES_HISTORY_COLUMNS';
export const RESCHEDULES_HISTORY_COLUMNS_SUCCESS =
  'pc/reschedules/RESCHEDULES_HISTORY_COLUMNS_SUCCESS';
export const RESCHEDULES_HISTORY_COLUMNS_FAIL =
  'pc/reschedules/RESCHEDULES_HISTORY_COLUMNS_FAIL';

export const RESCHEDULES_OPEN_ROWS_INFO =
  'pc/reschedules/RESCHEDULES_OPEN_ROWS_INFO';
export const RESCHEDULES_CLOSED_ROWS_INFO =
  'pc/reschedules/RESCHEDULES_CLOSED_ROWS_INFO';
export const RESCHEDULES_HISTORY_ROWS_INFO =
  'pc/reschedules/RESCHEDULES_HISTORY_ROWS_INFO';

export const RESCHEDULES_SUBMIT = 'pc/reschedules/RESCHEDULES_SUBMIT';
export const RESCHEDULES_SUBMIT_SUCCESS =
  'pc/reschedules/RESCHEDULES_SUBMIT_SUCCESS';
export const RESCHEDULES_SUBMIT_FAIL = 'pc/reschedules/RESCHEDULES_SUBMIT_FAIL';

export const DOCS_STD_SUCCESS = 'pc/reschedules/DOCS_STD_SUCCESS';

export const DESTROY_RESCHEDULES_BY_KEY =
  'pc/reschedules/DESTROY_RESCHEDULES_BY_KEY';

export const SET_FOCUSED_CELL_EDITOR = 'pc/reschedules/SET_FOCUSED_CELL_EDITOR';

export const UPDATE_LAST_ROW = 'pc/reschedules/UPDATE_LAST_ROW';

export const SET_PICKER_INFO_RES = 'pc/reschedules/SET_PICKER_INFO_RES';

const initialState = {
  newTab0: {
    req_number: [],
    loading: false,
    rowsInfo: {
      endRow: 0,
      lastRow: 0,
    },
    reschedulesOpenColumns: {},
    reschedulesClosedColumns: {},
    reschedulesHistoryColumns: {},
    doc_std_value: '',
    submittingItem: false,
    submittedItem: true,
    exitRowData: false,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESCHEDULES_LOV:
      return {
        ...state,
        reschedulesLov: {
          loading: true,
          holidays: {},
        },
      };
    case RESCHEDULES_LOV_SUCCESS:
      return {
        ...state,
        reschedulesLov: {
          loading: false,
          ...action.payload,
        },
      };
    case RESCHEDULES_LOV_ERROR:
      return {
        ...state,
        reschedulesLov: {
          loading: false,
          error: action.payload,
        },
      };
    case RESCHEDULES_OPEN_COLUMNS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          open: {
            loading: true,
          },
        },
      };
    case RESCHEDULES_OPEN_COLUMNS_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          open: {
            ...state[action.tabKey]['open'],
            loading: false,
            reschedulesOpenColumns: action.payload,
          },
        },
      };
    case RESCHEDULES_OPEN_COLUMNS_FAIL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          open: {
            ...state[action.tabKey]['open'],
            loading: false,
            error: action.payload,
          },
        },
      };
    case RESCHEDULES_OPEN_ROWS_INFO:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          open: {
            ...(state[action.tabKey]['open']
              ? state[action.tabKey]['open']
              : {}),
            rowsInfo: action.payload,
          },
        },
      };
    case RESCHEDULES_CLOSED_COLUMNS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          closed: {
            loading: true,
          },
        },
      };
    case RESCHEDULES_CLOSED_COLUMNS_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          closed: {
            ...state[action.tabKey]['closed'],
            loading: false,
            reschedulesClosedColumns: action.payload,
          },
        },
      };
    case RESCHEDULES_CLOSED_COLUMNS_FAIL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          closed: {
            ...state[action.tabKey]['closed'],
            loading: false,
            error: action.payload,
          },
        },
      };
    case RESCHEDULES_CLOSED_ROWS_INFO:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          closed: {
            ...(state[action.tabKey]['closed']
              ? state[action.tabKey]['closed']
              : {}),
            rowsInfo: action.payload,
          },
        },
      };
    case RESCHEDULES_HISTORY_COLUMNS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          history: {
            loading: true,
          },
        },
      };
    case RESCHEDULES_HISTORY_COLUMNS_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          history: {
            ...state[action.tabKey]['history'],
            loading: false,
            reschedulesHistoryColumns: action.payload,
          },
        },
      };
    case RESCHEDULES_HISTORY_COLUMNS_FAIL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          history: {
            ...state[action.tabKey]['history'],
            loading: false,
            error: action.payload,
          },
        },
      };
    case RESCHEDULES_HISTORY_ROWS_INFO:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          history: {
            ...(state[action.tabKey]['history']
              ? state[action.tabKey]['history']
              : {}),
            rowsInfo: action.payload,
          },
        },
      };
    case RESCHEDULES_SUBMIT:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          submittingItem: true,
        },
      };
    case RESCHEDULES_SUBMIT_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          submittingItem: false,
          submittedItem: true,
        },
      };
    case RESCHEDULES_SUBMIT_FAIL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          submittingItem: false,
          error: action.payload,
        },
      };
    case DESTROY_RESCHEDULES_BY_KEY:
      delete state[action.tabKey];
      return state;
    case SET_FOCUSED_CELL_EDITOR:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          focusedCell: {
            colId: action.colId,
            rowIndex: action.rowIndex,
          },
        },
      };
    case UPDATE_LAST_ROW:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          open: {
            ...(state[action.tabKey]['open']
              ? state[action.tabKey]['open']
              : {}),
            rowsInfo: {
              ...(state[action.tabKey]['open']['rowsInfo']
                ? state[action.tabKey]['open']['rowsInfo']
                : {}),
              lastRow: action.lastRow,
            },
          },
        },
      };
    case SET_PICKER_INFO_RES:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          datePicker: {
            ...action.payload,
          },
        },
      };
    default:
      return state;
  }
}

const getSplitRows = (rowData, parentId) => {
  const splitDataSize = rowData.length;
  const totalSplitSize = 5;
  const emptyObjectSize = totalSplitSize - splitDataSize;
  const emptyObjArr = [];
  let splitId = 1;

  let existingSplitData = [];
  if (rowData.length) {
    existingSplitData = rowData.map((row) => {
      const splitIdIncr = splitId++;
      return {
        ...row,
        id: `${parentId}_${splitIdIncr}`,
        originalValues: { ...row },
        parentId,
      };
    });
  }

  if (emptyObjectSize) {
    for (let i = 0; i < emptyObjectSize; i++) {
      const splitIdIncr = splitId++;
      emptyObjArr.push({
        originalValues: {},
        id: `${parentId}_${splitIdIncr}`,
        parentId,
      });
    }
  }

  return [...existingSplitData, ...emptyObjArr];
};

const handleNoRowsOverlay = (rowData, params) => {
  const rowsPerRequest = process.env.REACT_APP_AG_GRID_ROWS_LIMIT;
  if (!rowData.length && params.request.startRow < rowsPerRequest) {
    params.api.showNoRowsOverlay();
  }
};

const getGridSuccessResponse = (rowData, params) => {
  const doingInfinite = !params.request.groupKeys.length;
  return doingInfinite
    ? {
        rowData: rowData,
        rowCount: getLastRowIndex(params.request, rowData),
      }
    : { rowData: rowData };
};

const getEndRow = (data, params) =>
  params.request.endRow > data.result.lastRow
    ? data.result.lastRow
    : params.request.endRow;

const isReachedLastRow = (lastRow, endRowNew) =>
  lastRow > 0 && endRowNew > lastRow;

const getView = (viewType, parentId) => {
  return !parentId.length ? viewType : `${viewType}_SPLIT`;
};

const getQsProps = (tabKey) => {
  const qsState = store.getState().pc.search.quickSearch;
  const tabData = qsState[tabKey] || {};

  const stringify = (val = '') => {
    if (typeof val !== 'string') {
      const filteredVal = val.filter((data) => data !== 'all');
      return filteredVal.join();
    } else {
      return val;
    }
  };

  return {
    customer_plant: stringify(tabData.customerPlant),
    supplier_name: stringify(tabData.supplierName),
    supplier_code: stringify(tabData.supplierCode),
    supplier_key: stringify(tabData.supplierDepot),
    site_name: stringify(tabData.supplierSite),
    agility_type: stringify(tabData.agilityType),
    search_term: tabData.searchTerm,
  };
};

const getReschedulesLov = () => {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: RESCHEDULES_LOV });
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'LOV_DATA',
            view: `RESCHEDULES`,
            details: {
              email: getEmail(),
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data && data.statusCode === '200' && isObject(data.result)) {
          dispatch({ type: RESCHEDULES_LOV_SUCCESS, payload: data.result });
        } else {
          dispatch({
            type: RESCHEDULES_LOV_ERROR,
            payload: 'Something went wrong!',
          });
        }
      });
  };
};

const getLastRow = (tabKey, view) => {
  const rowInfoState = store.getState().pc.reschedules[view];
  const tabData = rowInfoState ? rowInfoState[tabKey] : false;
  if (tabData) {
    return tabData.rowsInfo.lastRow;
  } else {
    return 0;
  }
};

function getReschedulesOpenColumns(tabKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: RESCHEDULES_OPEN_COLUMNS, tabKey });
    const currentTime = getEpochTime();
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'AG_GRID_COLUMNS',
            view: `RESCHEDULES_OPEN`,
            details: {
              email: getEmail(),
            },
          },
          getState
        )
      )
      .then((response) => {
        gaPageviewTimingTitle(
          `/CDS/GET_RESCHEDULES_OPEN_AG_GRID_COLUMNS`,
          currentTime
        );
        const { data } = response;
        if (data && data.statusCode === '200') {
          let {
            detailCellRendererParams,
            masterDetail,
            detailRowAutoHeight,
            groupSelectsChildren,
            animateRows,
            serverSideStoreType,
            enableGroupEdit,
            columnDefs,
            ...configs
          } = data.result;

          let dateFields = [];
          const columnDefsModified = columnDefs.map((columnDef) => {
            if (columnDef.type === 'date') {
              dateFields = [...dateFields, columnDef.colId];
              return {
                ...columnDef,
                floatingFilterComponent: 'agCustomDateFloatingFilter',
                filter: 'agCustomDateFilter',
              };
            }
            return columnDef;
          });

          dispatch({
            type: RESCHEDULES_OPEN_COLUMNS_SUCCESS,
            payload: { ...configs, columnDefs: columnDefsModified, dateFields },
            tabKey,
          });
        } else {
          dispatch({
            type: RESCHEDULES_OPEN_COLUMNS_FAIL,
            payload: 'Error in loading testing form!',
            tabKey,
          });
        }
      });
  };
}

const getRowDataFromResponseOpen = (data, params, tabKey) => {
  let rowData = [];
  if (params.request.groupKeys.length) {
    rowData = getSplitRows(data.result.rowData, params.request.groupKeys[0]);
  } else {
    rowData = data.result.rowData.map((row) => {
      return {
        ...row,
        group: true,
        id: `${row.request_id}$$$${getUUID()}`,
        isEditing: false,
        errors: {},
        hasError: false,
        originalValues: { ...row },
        changedFields: {
          supplier_cancel_qty: false,
          new_date_confirmed: false,
        },
      };
    });

    const endRow = getEndRow(data, params);

    store.dispatch({
      type: RESCHEDULES_OPEN_ROWS_INFO,
      payload: { lastRow: data.result.lastRow, endRow },
      tabKey,
    });
  }

  return rowData;
};

const getParentId = (params) => {
  const idOriginal = params.request.groupKeys.join();
  const idExtract = idOriginal.split('$$$');
  return idExtract[0] || null;
};

function getReschedulesOpenRows(tabKey) {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      // Restrict request if last row reached
      const lastRow = getLastRow(tabKey, 'open');
      const endRowNew = params.request.endRow;
      if (isReachedLastRow(lastRow, endRowNew)) {
        setTimeout(() => {
          params.success({ rowData: [], rowCount: lastRow });
        }, 0);
      } else {
        params.api.showLoadingOverlay();
        const currentTime = getEpochTime();
        return api
          .post(
            'pc',
            formatParams(
              {
                type: 'AG_GRID_ROWS',
                view: getView(`RESCHEDULES_OPEN`, params.request.groupKeys),
                details: {
                  email: getEmail(),
                  ...getQsProps(tabKey),
                  parentId: getParentId(params),
                },
                gridParams: {
                  ...params.request,
                },
              },
              store.getState
            )
          )
          .then((response) => {
            gaPageviewTimingTitle(
              `/CDS/GET_RESCHEDULES_OPEN_AG_GRID_ROWS`,
              currentTime
            );
            const { data } = response;
            if (data?.statusCode === '200') {
              let rowData = getRowDataFromResponseOpen(data, params, tabKey);
              const result = getGridSuccessResponse(rowData, params);
              setTimeout(() => {
                params.success(result);
              }, 200);

              // Hide overlay loading on success
              params.api.hideOverlay();
              params.api.refreshHeader();
              handleNoRowsOverlay(rowData, params);
            } else {
              params.fail();
            }
          })
          .catch((_error) => {
            params.fail();
          });
      }
    },
  };
}

function getReschedulesClosedColumns(tabKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: RESCHEDULES_CLOSED_COLUMNS, tabKey });
    const currentTime = getEpochTime();
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'AG_GRID_COLUMNS',
            view: `RESCHEDULES_CLOSED`,
            details: {
              email: getEmail(),
            },
          },
          getState
        )
      )
      .then((response) => {
        gaPageviewTimingTitle(
          `/CDS/GET_RESCHEDULES_CLOSED_AG_GRID_COLUMNS`,
          currentTime
        );
        const { data } = response;
        if (data && data.statusCode === '200') {
          let {
            detailCellRendererParams,
            masterDetail,
            detailRowAutoHeight,
            groupSelectsChildren,
            animateRows,
            serverSideStoreType,
            columnDefs,
            ...configs
          } = data.result;

          let dateFields = [];
          const columnDefsModified = columnDefs.map((columnDef) => {
            if (columnDef.type === 'date') {
              dateFields = [...dateFields, columnDef.colId];
              return {
                ...columnDef,
                floatingFilterComponent: 'agCustomDateFloatingFilter',
                filter: 'agCustomDateFilter',
              };
            }
            return columnDef;
          });
          const configDetails = {
            ...configs,
            columnDefs: columnDefsModified,
            dateFields,
          };

          dispatch({
            type: RESCHEDULES_CLOSED_COLUMNS_SUCCESS,
            payload: configDetails,
            tabKey,
          });
        } else {
          dispatch({
            type: RESCHEDULES_CLOSED_COLUMNS_FAIL,
            payload: 'Error in loading testing form!',
            tabKey,
          });
        }
      });
  };
}

const getRowDataFromResponseClosed = (data, params, tabKey) => {
  let rowData = data.result.rowData;
  if (!params.request.groupKeys.length) {
    rowData = rowData.map((row) => ({
      ...row,
      id: `${row.request_id}$$$${getUUID()}`,
      group: true,
    }));

    const endRow = getEndRow(data, params);

    store.dispatch({
      type: RESCHEDULES_CLOSED_ROWS_INFO,
      payload: { lastRow: data.result.lastRow, endRow },
      tabKey,
    });
  } else {
    rowData = getSplitRows(data.result.rowData, params.request.groupKeys[0]);
  }

  return rowData;
};

function getReschedulesClosedRows(tabKey) {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      // Restrict request if last row reached
      const lastRow = getLastRow(tabKey, 'closed');
      const endRowNew = params.request.endRow;
      if (isReachedLastRow(lastRow, endRowNew)) {
        setTimeout(() => {
          params.success({ rowData: [], rowCount: lastRow });
        }, 0);
      } else {
        params.api.showLoadingOverlay();
        const currentTime = getEpochTime();
        return api
          .post(
            'pc',
            formatParams(
              {
                type: 'AG_GRID_ROWS',
                view: getView(`RESCHEDULES_CLOSED`, params.request.groupKeys),
                details: {
                  email: getEmail(),
                  ...getQsProps(tabKey),
                  parentId: getParentId(params),
                },
                gridParams: {
                  ...params.request,
                },
              },
              store.getState
            )
          )
          .then((response) => {
            gaPageviewTimingTitle(
              `/CDS/GET_RESCHEDULES_CLOSED_AG_GRID_ROWS`,
              currentTime
            );
            const { data } = response;
            if (data?.statusCode === '200') {
              let rowDataClosed = getRowDataFromResponseClosed(
                data,
                params,
                tabKey
              );
              const result = getGridSuccessResponse(rowDataClosed, params);
              setTimeout(() => {
                params.success(result);
              }, 200);

              // Hide overlay loading on success
              params.api.hideOverlay();
              handleNoRowsOverlay(rowDataClosed, params);
            } else {
              params.fail();
            }
          })
          .catch((_error) => {
            params.fail();
          });
      }
    },
  };
}

function getReschedulesHistoryColumns(tabKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: RESCHEDULES_HISTORY_COLUMNS, tabKey });
    const currentTime = getEpochTime();
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'AG_GRID_COLUMNS',
            view: `RESCHEDULES_HISTORY`,
            details: {
              email: getEmail(),
            },
          },
          getState
        )
      )
      .then((response) => {
        gaPageviewTimingTitle(
          `/CDS/GET_RESCHEDULES_HISTORY_AG_GRID_COLUMNS`,
          currentTime
        );
        const { data } = response;
        if (data && data.statusCode === '200') {
          let {
            detailCellRendererParams,
            masterDetail,
            detailRowAutoHeight,
            groupSelectsChildren,
            animateRows,
            serverSideStoreType,
            columnDefs,
            ...configs
          } = data.result;

          let dateFields = [];
          const columnDefsModified = columnDefs.map((columnDef) => {
            if (columnDef.type === 'date') {
              dateFields = [...dateFields, columnDef.colId];
              return {
                ...columnDef,
                floatingFilterComponent: 'agCustomDateFloatingFilter',
                filter: 'agCustomDateFilter',
              };
            }
            return columnDef;
          });
          const configDetails = {
            ...configs,
            columnDefs: columnDefsModified,
            dateFields,
          };

          dispatch({
            type: RESCHEDULES_HISTORY_COLUMNS_SUCCESS,
            payload: configDetails,
            tabKey,
          });
        } else {
          dispatch({
            type: RESCHEDULES_HISTORY_COLUMNS_FAIL,
            payload: 'Error in loading testing form!',
            tabKey,
          });
        }
      });
  };
}

const getRowDataFromResponseHist = (data, params, tabKey) => {
  let rowData = data.result.rowData;
  if (!params.request.groupKeys.length) {
    rowData = rowData.map((d) => ({
      ...d,
      id: `${d.request_id}$$$${getUUID()}`,
      group: true,
    }));

    const endRow = getEndRow(data, params);

    store.dispatch({
      type: RESCHEDULES_HISTORY_ROWS_INFO,
      payload: { lastRow: data.result.lastRow, endRow },
      tabKey,
    });
  } else {
    rowData = getSplitRows(data.result.rowData, params.request.groupKeys[0]);
  }

  return rowData;
};

function getReschedulesHistoryRows(tabKey) {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      // Restrict request if last row reached
      const lastRow = getLastRow(tabKey, 'history');
      const endRowNew = params.request.endRow;

      if (isReachedLastRow(lastRow, endRowNew)) {
        setTimeout(() => {
          params.success({ rowData: [], rowCount: lastRow });
        }, 0);
      } else {
        params.api.showLoadingOverlay();
        const currentTime = getEpochTime();
        return api
          .post(
            'pc',
            formatParams(
              {
                type: 'AG_GRID_ROWS',
                view: getView(`RESCHEDULES_HISTORY`, params.request.groupKeys),
                details: {
                  email: getEmail(),
                  ...getQsProps(tabKey),
                  parentId: getParentId(params),
                },
                gridParams: {
                  ...params.request,
                },
              },
              store.getState
            )
          )
          .then((response) => {
            gaPageviewTimingTitle(
              `/CDS/GET_RESCHEDULES_HISTORY_AG_GRID_ROWS`,
              currentTime
            );
            const { data } = response;
            if (data?.statusCode === '200') {
              let rowDataHist = getRowDataFromResponseHist(
                data,
                params,
                tabKey
              );
              const result = getGridSuccessResponse(rowDataHist, params);
              setTimeout(() => {
                params.success(result);
              }, 200);

              // Hide overlay loading on success
              params.api.hideOverlay();
              handleNoRowsOverlay(rowDataHist, params);
            } else {
              params.fail();
            }
          })
          .catch((_error) => {
            params.fail();
          });
      }
    },
  };
}

const getSupplierAction = (node) => {
  let supplierAction = node.supplier_action;

  const { new_date_confirmed, supplier_cancel_qty } = node.changedFields;

  const isFieldsChanged = supplier_cancel_qty || new_date_confirmed;

  if (isFieldsChanged) {
    if (supplierAction === '3 - Accepted') {
      supplierAction = '4 - Accepted w/ Change';
    }
  } else if (supplierAction === '4 - Accepted w/ Change') {
    supplierAction = '3 - Accepted';
  }

  return supplierAction;
};

const prepareReleaseCommitData = (masterNodes) => {
  let RescheduleArray = [];
  masterNodes.forEach((node) => {
    RescheduleArray.push({
      customer_plant: node.customer_plant,
      supplier_key: node.supplier_key,
      part: node.part,
      request_id: node.request_id,
      split_id: node.split_id,
      lt: node.leadtime,
      supplier_lead_time: node.supplier_lead_time,
      open_qty: node.order_qty,
      cancel_qty: node.supplier_cancel_qty,
      date:
        node.new_date_confirmed === 'Cancel'
          ? node.new_date_confirmed
          : getFormattedDateStr(node.new_date_confirmed),
      action: getSupplierAction(node),
      reason: node.reason_code,
      comment: node.supplier_comment,
      price: node.supplier_price,
    });

    let splitCount = 1;
    if (node.splitData?.length) {
      node.splitData.forEach((splitNode) => {
        if (splitNode.order_qty !== undefined) {
          RescheduleArray.push({
            customer_plant: node.customer_plant,
            supplier_key: node.supplier_key,
            part: node.part,
            request_id: node.request_id,
            split_id: splitCount.toString(),
            lt: node.leadtime,
            supplier_lead_time: node.supplier_lead_time,
            open_qty: splitNode.order_qty,
            cancel_qty: node.supplier_cancel_qty,
            date: splitNode.new_date_confirmed,
            action: node.supplier_action,
            reason: node.reason_code,
            comment: node.supplier_comment,
            price: node.supplier_price,
          });
          splitCount++;
        }
      });
    }
  });

  return {
    RescheduleArray: RescheduleArray,
  };
};

function submitData(nodes, callback, tabKey, t) {
  let headers = prepareReleaseCommitData(nodes);
  return (dispatch, _getState, { api }) => {
    dispatch({ type: RESCHEDULES_SUBMIT, tabKey });
    return api
      .post('pcsubmitaction', {
        TXN: {
          Header: {
            uuid: getUUID(),
            excel: 'NO',
            source: 'PORTAL',
            txn_type: `RESCHEDULE`,
            requestor: Cookie.get('email'),
            customer: 'SANM',
          },
          ...headers,
        },
      })
      .then((response) => {
        const { data } = response;
        if (
          data == 1 ||
          data == 'SUCCESS' ||
          (data.TXN && data.TXN[0].return_msg == 'SUCCESS')
        ) {
          callback(true);
          message.success(t('data_saved_successfully'));
        } else {
          const failureResponse = data.TXN?.[0]?.RescheduleArray;
          if (!failureResponse || failureResponse.length == 0) {
            callback(false);
            message.error(t('something_went_wrong'));
          } else {
            if (failureResponse.length == nodes.length) callback(false);
            else callback(true);

            Modal.error({
              className: 'ccut-text',
              title: 'Update Failed!!',
              icon: <ExclamationCircleOutlined />,
              width: 520,
              content: (
                <div
                  style={{
                    width: 430,
                    overflowY: 'auto',
                  }}>
                  <div
                    style={{
                      borderBottom: '1px solid #CCC',
                      padding: 5,
                      fontSize: 12,
                      fontWeight: 'bold',
                    }}>
                    <Row>
                      <Col style={{ textAlign: 'center' }} span={2} order={1}>
                        Plant
                      </Col>
                      <Col style={{ textAlign: 'center' }} span={6} order={2}>
                        Supplier
                      </Col>
                      <Col style={{ textAlign: 'center' }} span={7} order={3}>
                        Part
                      </Col>
                      <Col style={{ textAlign: 'center' }} span={9} order={4}>
                        Error
                      </Col>
                    </Row>
                  </div>
                  {failureResponse[0].map((item) => (
                    <div
                      key={getUUID()}
                      style={{
                        borderBottom: '1px solid #CCC',
                        padding: 5,
                        fontSize: 10,
                      }}>
                      <Row>
                        <Col style={{ textAlign: 'center' }} span={2} order={1}>
                          {item.customer_plant}
                        </Col>
                        <Col style={{ textAlign: 'center' }} span={6} order={2}>
                          {item.supplier_key}
                        </Col>
                        <Col style={{ textAlign: 'center' }} span={7} order={3}>
                          {item.part}
                        </Col>
                        <Col
                          style={{
                            textAlign: 'center',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                          span={9}
                          order={4}>
                          <span title={item.err_msg}>{item.err_msg}</span>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </div>
              ),
            });
          }
        }
        return response;
      });
  };
}

const handleRowsWithSplitAdd = (result, callBack) => {
  result.rowData.forEach((row) => {
    const { splitRecords, ...rest } = row;
    callBack(rest);
    if (splitRecords.length) {
      splitRecords.forEach((splitRow) => callBack(splitRow));
    }
  });
};

export function getMassDownloadReport({
  gridApi,
  exportType,
  totalRows,
  cb,
  columnApi,
  fileName,
  view,
  tabKey,
  columnsExclude,
}) {
  return (_dispatch, getState, { api, formatParams }) => {
    if (totalRows >= process.env.REACT_APP_MASS_DOWNLOAD_ENABLE_LIMIT) {
      return api
        .post(
          'pc',
          formatParams(
            {
              type: 'MASS_DOWNLOAD',
              view: view,
              exportType,
              totalRows,
              email: getEmail(),
              details: {
                email: getEmail(),
                ...getQsProps(tabKey),
              },
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
              },
            },
            getState
          )
        )
        .then((response) => {
          cb(true);
          return response;
        })
        .catch((error) => {
          cb(false);
          return error;
        });
    } else {
      const currentTime = getEpochTime();
      return api
        .post(
          'pc',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: view,
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
                startRow: 0,
                endRow: totalRows,
              },
              details: {
                email: getEmail(),
                ...getQsProps(tabKey),
              },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(
            `/CDS/GET_${view}_MASS_DOWNLOAD_REPORT`,
            currentTime
          );
          const { data } = response;
          if (data) {
            const { result } = data;
            if (Object.keys(result).length) {
              let rowsWithSplitAdded = [];
              handleRowsWithSplitAdd(result, (d) => rowsWithSplitAdded.push(d));
              exportDTo({
                columnApi,
                fileName: fileName,
                data: rowsWithSplitAdded,
                exportType,
                columnsExclude,
              }) && cb(true);
            }
          }

          return response;
        });
    }
  };
}

const destroyReschedulesByKey = (tabKey) => {
  return (dispatch) => {
    dispatch({ type: DESTROY_RESCHEDULES_BY_KEY, tabKey });
  };
};

const setFocusedCell = (colId, rowIndex, tabKey) => {
  return (dispatch) => {
    dispatch({ type: SET_FOCUSED_CELL_EDITOR, colId, rowIndex, tabKey });
  };
};

const updateLastRow = (tabKey, lastRow) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_LAST_ROW,
      tabKey,
      lastRow,
    });
  };
};

const setPickerInfo = (payload, tabKey) => {
  return (dispatch) => {
    dispatch({
      type: SET_PICKER_INFO_RES,
      payload,
      tabKey,
    });
  };
};

export {
  getReschedulesOpenColumns,
  getReschedulesOpenRows,
  getReschedulesClosedColumns,
  getReschedulesClosedRows,
  getReschedulesHistoryColumns,
  getReschedulesHistoryRows,
  submitData,
  destroyReschedulesByKey,
  getReschedulesLov,
  setFocusedCell,
  updateLastRow,
  setPickerInfo,
};
