import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Avatar, Modal, Dropdown, Space } from 'antd';
import {
  UserOutlined,
  ExclamationCircleOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import ProfileModal from '../../profile/Profile';
import { destroyTabs } from '../redux/modules/layout';
import Cookies from 'js-cookie';
import { resetInitAuthorization } from '../redux/modules/authorization';
import { setGlobalSearchProps } from '../../pc/search/redux/search';

function SiderProfile(props) {
  const [visible, setVisible] = useState(false);
  const [countP, setCountP] = useState(false);
  const [open, setOpen] = useState(false);
  const { tourReferences, userName } = useSelector(
    ({ tour, authorization }) => ({
      tourReferences: tour.tourReferences,
      userName: authorization.initAuthorization.userName,
    })
  );

  const { tabs } = useSelector(({ layout }) => ({
    tabs: layout.tabLayout.tabs,
  }));
  const keyVal = useSelector(
    ({ layout: { tabLayout } }) => tabLayout.activeKey
  );
  let activeKey =
    keyVal !== null ? (tabs.length > 0 ? keyVal : 'dashboard') : 'dashboard';
  const visibleSearchObj = useSelector(
    ({ pc: { search } }) => search.visibleQsandSs
  );

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const handleCancel = () => {
    setVisible(false);
    setCountP(false);
  };

  const closeGlobalSearch = () => {
    if (
      visibleSearchObj?.[activeKey]?.['visibleQs'] ||
      visibleSearchObj?.[activeKey]?.['visibleSs']
    ) {
      dispatch(
        setGlobalSearchProps(activeKey, {
          visibleQs: false,
          visibleSs: false,
        })
      );
    }
  };

  const showProfile = () => {
    closeGlobalSearch();
    setVisible(true);
    const timeout = setTimeout(() => {
      setCountP(true);
    }, 1000);
    return () => clearTimeout(timeout);
  };

  const logOutFunction = () => {
    Modal.confirm({
      title: 'Logout',
      icon: <ExclamationCircleOutlined />,
      content:
        'To securely log out of the Portal please close all windows of your current Internet Browser.',
      onOk: () => {
        dispatch(destroyTabs());
        dispatch(resetInitAuthorization());
        Cookies.set('continueSession', false, { expires: 365 });
        navigate('/');
      },
    });
  };

  const items = [
    {
      label: (
        <Link to onClick={() => showProfile()}>
          <UserOutlined /> My Profile
        </Link>
      ),
      key: '0',
    },
    {
      label: (
        <Link to onClick={() => logOutFunction()}>
          <LogoutOutlined /> Logout
        </Link>
      ),
      key: '1',
    },
  ];

  return (
    <>
      <ProfileModal
        visible={visible}
        title='My Profile'
        okText='Save'
        onCancel={handleCancel}
        {...props}
        handleVisibility={() => setVisible(!visible)}
        showWalkthrough={visible}
        countP={countP}
      />
      <Space wrap></Space>
      <Dropdown
        menu={{
          items,
        }}
        trigger={['click']}
        placement='bottomRight'>
        <Link to onClick={(e) => e.preventDefault()}>
          <Avatar
            ref={tourReferences.profileSpaceTour}
            title={userName}
            className='user-avatar'
            size='medium'
            style={{ cursor: 'pointer' }}>
            {userName ? userName.charAt(0).toUpperCase() : ''}
          </Avatar>
          <span className='user-name'> {userName}</span>
        </Link>
      </Dropdown>
    </>
  );
}

export default SiderProfile;
