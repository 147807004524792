import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(weekday);
dayjs.extend(localeData);

const getObjectValue = (obj, key) => obj[key] || {};

const getFormattedDate = (date) => (date ? dayjs(date, 'YYYY-MM-DD') : '');

const getFormattedDateStr = (date) =>
  date ? dayjs(date).format('YYYY-MM-DD') : '';

const setDisableTextboxExpanded = (
  props,
  colId,
  asnEnabledStatus,
  callBack
) => {
  if (
    (!asnEnabledStatus &&
      props.node.data.isChildNodeSelected &&
      (colId === 'supplier_commit_qty' ||
        colId === 'shipment_no' ||
        colId === 'asn_ship_qty' ||
        colId === 'line_number' ||
        colId === 'carriers' ||
        colId === 'service_level' ||
        colId === 'ship_method' ||
        colId === 'packing_slip' ||
        colId === 'waybill_number' ||
        colId === 'net_weight' ||
        colId === 'weight_units')) ||
    (!asnEnabledStatus &&
      !props.node.data.isChildNodeSelected &&
      (colId === 'shipment_no' ||
        colId === 'asn_ship_qty' ||
        colId === 'line_number' ||
        colId === 'carriers' ||
        colId === 'service_level' ||
        colId === 'ship_method' ||
        colId === 'packing_slip' ||
        colId === 'waybill_number' ||
        colId === 'net_weight' ||
        colId === 'weight_units')) ||
    (asnEnabledStatus && props.node.data.asn_flag == 'No') ||
    (asnEnabledStatus &&
      props.node.data.asn_flag == 'Yes' &&
      (colId === 'supplier_commit_qty' ||
        colId === 'rel_mpn' ||
        colId === 'price'))
  ) {
    callBack(true);
  } else {
    callBack(false);
  }
};

const setDisableTextboxCollapsed = (
  props,
  colId,
  asnEnabledStatus,
  callBack
) => {
  if (
    (asnEnabledStatus && props.node.data.asn_flag == 'No') ||
    (asnEnabledStatus &&
      props.node.data.asn_flag == 'Yes' &&
      (colId === 'supplier_commit_qty' ||
        colId === 'rel_mpn' ||
        colId === 'price')) ||
    (!asnEnabledStatus &&
      (colId === 'shipment_no' ||
        colId === 'asn_ship_qty' ||
        colId === 'line_number' ||
        colId === 'carriers' ||
        colId === 'service_level' ||
        colId === 'ship_method' ||
        colId === 'packing_slip' ||
        colId === 'waybill_number' ||
        colId === 'net_weight' ||
        colId === 'weight_units'))
  ) {
    callBack(true);
  } else {
    callBack(false);
  }
};

const focusCell = ({ screenData, colId, rowIndex, refInput }) => {
  if (
    screenData.focusedCell &&
    colId === screenData.focusedCell.colId &&
    rowIndex === screenData.focusedCell.rowIndex &&
    refInput.current
  ) {
    refInput.current.focus();
  }
};

const refreshRow = (props) => {
  const originalId = props.node.data.id;
  const dupeId = 'test';
  const dupeIdParent = 'test1';

  props.node.setSelected(true);
  let isChildNodeSelected = false; //used when child node selected, parent row should be disabled
  if (props.node.isSelected()) isChildNodeSelected = true;

  props.node.updateData({
    ...props.node.data,
    id: dupeId,
    isChildNodeSelected: isChildNodeSelected,
  });

  setTimeout(() => {
    props.node.updateData({
      ...props.node.data,
      id: originalId,
      isChildNodeSelected: isChildNodeSelected,
    });
  }, 0);

  if (!props.node.data.group) {
    props.node.parent.setSelected(true);
    props.node.parent.updateData({
      ...props.node.parent.data,
      id: dupeIdParent,
    });
    setTimeout(() => {
      props.node.parent.updateData({
        ...props.node.parent.data,
        id: originalId.split('__')[0],
      });
    }, 0);
  }
};

const handleRefreshOnValueChange = (props) => {
  if (!props.node.data.group) {
    if (!props.node.isSelected()) {
      props.node.setSelected(true); //used when child node selected, parent row should be disabled
      let isChildNodeSelected = false;
      if (props.node.isSelected()) isChildNodeSelected = true;

      props.node.parent.updateData({
        ...props.node.parent.data,
        isChildNodeSelected: isChildNodeSelected,
      });

      refreshRow(props);
      props.api.refreshHeader();
    }
  } else if (!props.node.isSelected()) {
    props.node.setSelected(true);
  }

  props.api.refreshCells({ force: true });
};

const handleParentDisableCheck = (props, colId, asnEnabledStatus, callBack) => {
  if (props.node.expanded) {
    setDisableTextboxExpanded(props, colId, asnEnabledStatus, callBack);
  } else {
    setDisableTextboxCollapsed(props, colId, asnEnabledStatus, callBack);
  }
};

const handleLeafDisableCheck = (asnEnabledStatus, colId, callBack) => {
  if (asnEnabledStatus && colId === 'supplier_commit_qty') {
    callBack(true);
  } else {
    callBack(false);
  }
};

const getErrorDesc = (errorDesc) => (
  <div style={{ fontSize: 12 }}>{errorDesc}</div>
);

const getMandatoryClass = (requiredColumns, colId) =>
  requiredColumns.includes(colId) ? 'mandatory' : '';

const setDisabledCheckboxExpanded = (
  props,
  colId,
  asnEnabledStatus,
  callBack
) => {
  if (!asnEnabledStatus) {
    if (
      (props.node.data.isChildNodeSelected &&
        colId === 'supplier_arrival_date') ||
      (!props.node.data.isChildNodeSelected &&
        (colId === 'ship_date' || colId === 'expt_receipt_date'))
    ) {
      callBack(true);
    } else {
      callBack(false);
    }
  } else if (asnEnabledStatus) {
    if (
      props.node.data.asn_flag == 'No' ||
      (props.node.data.asn_flag == 'Yes' && colId === 'supplier_arrival_date')
    ) {
      callBack(true);
    } else {
      callBack(false);
    }
  }
};

const setDisabledCheckboxCollapsed = (
  props,
  colId,
  asnEnabledStatus,
  callBack
) => {
  if (
    (asnEnabledStatus && props.node.data.asn_flag == 'No') ||
    (asnEnabledStatus &&
      props.node.data.asn_flag == 'Yes' &&
      colId === 'supplier_arrival_date') ||
    (!asnEnabledStatus &&
      (colId === 'ship_date' || colId === 'expt_receipt_date'))
  ) {
    callBack(true);
  } else {
    callBack(false);
  }
};

const handleParentDisableCheckForDate = (
  props,
  colId,
  asnEnabledStatus,
  callBack
) => {
  if (props.node.expanded) {
    setDisabledCheckboxExpanded(props, colId, asnEnabledStatus, callBack);
  } else {
    setDisabledCheckboxCollapsed(props, colId, asnEnabledStatus, callBack);
  }
};

const handleLeafDisableCheckForDate = (asnEnabledStatus, colId, callBack) => {
  if (asnEnabledStatus && colId === 'supplier_arrival_date') {
    callBack(true);
  }
};

const focusDatePickerCell = ({
  screenData,
  colId,
  rowIndex,
  refDatePicker,
  callBack,
}) => {
  if (
    screenData.focusedCell &&
    colId === screenData.focusedCell.colId &&
    rowIndex === screenData.focusedCell.rowIndex &&
    refDatePicker?.current
  ) {
    refDatePicker.current.focus();
    callBack(true);
  }
};

const isPastDate = (current, serverDate) => {
  return current < dayjs(serverDate).subtract(dayjs(serverDate).day(), 'day');
};

const getDisabledDates = (current, colId, serverDate) => {
  if (colId === 'supplier_arrival_date') {
    return (
      isPastDate(current, serverDate) ||
      dayjs(current) > dayjs(serverDate).add(365, 'day')
    );
  } else if (colId === 'ship_date' || colId === 'expt_receipt_date') {
    return (
      isPastDate(current, serverDate) ||
      dayjs(current) > dayjs(serverDate).add(30, 'day')
    );
  }
};

const getValue = (value) => {
  return value !== '' && value !== null ? getFormattedDate(value) : '';
};

const getTextValue = (e, colId) =>
  colId === 'supplier_commit_qty' && isNaN(e.target.value) ? 0 : e.target.value;

const isCellHasErrors = (errors, colId) =>
  errors && errors[colId] !== undefined;

export {
  getObjectValue,
  setDisableTextboxExpanded,
  setDisableTextboxCollapsed,
  focusCell,
  handleRefreshOnValueChange,
  getErrorDesc,
  getMandatoryClass,
  handleParentDisableCheck,
  handleLeafDisableCheck,
  setDisabledCheckboxCollapsed,
  setDisabledCheckboxExpanded,
  handleParentDisableCheckForDate,
  handleLeafDisableCheckForDate,
  focusDatePickerCell,
  isPastDate,
  getDisabledDates,
  getValue,
  getFormattedDate,
  getTextValue,
  isCellHasErrors,
  getFormattedDateStr,
};
