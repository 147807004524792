import { message, Row, Col, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Cookie from 'js-cookie';
import store from '../../../../redux/store';
import service from '../../../../service/Service';
import {
  getLastRowIndex,
  getUUID,
  isObject,
  getEmail,
  gaPageviewTimingTitle,
  getEpochTime,
} from '../../../../helpers/utils';
import {
  exportDTo,
  getMassDownloadGridParams,
} from '../../../../helpers/export';
import selectColumn from 'modules/pc/vci/components/SelectColObj';

const DVR_REPORT = 'pc/vci/DVR_REPORT';
const DVR_REPORT_SUCCESS = 'pc/vci/DVR_REPORT_SUCCESS';
const DVR_REPORT_FAIL = 'pc/vci/DVR_REPORT_FAIL';
const DVR_ROWS_INFO = 'pc/vci/DVR_ROWS_INFO';

const SHIPMENTS = 'pc/vci/SHIPMENTS';
const SHIPMENTS_SUCCESS = 'pc/vci/SHIPMENTS_SUCCESS';
const SHIPMENTS_FAIL = 'pc/vci/SHIPMENTS_FAIL';
const SHIPMENTS_ROWS_INFO = 'pc/vci/SHIPMENTS_ROWS_INFO';

const SHIPMENTS_HISTORY = 'pc/vci/SHIPMENTS_HISTORY';
const SHIPMENTS_HISTORY_SUCCESS = 'pc/vci/SHIPMENTS_HISTORY_SUCCESS';
const SHIPMENTS_HISTORY_FAIL = 'pc/vci/SHIPMENTS_HISTORY_FAIL';
const SHIPMENTS_HISTORY_ROWS_INFO = 'pc/vci/SHIPMENTS_HISTORY_ROWS_INFO';

const DESTROY_VCI_BY_KEY = 'pc/vci/DESTROY_VCI_BY_KEY';

const SET_FOCUSED_CELL_EDITOR = 'pc/vci/SET_FOCUSED_CELL_EDITOR';

const SET_PICKER_INFO_VCI = 'pc/vci/SET_PICKER_INFO_VCI';

const initialState = {
  dvr: {},
  shipments: {},
  shipmentsHistory: {},
};

export default function reducer(state = initialState, action = null) {
  switch (action.type) {
    case DVR_REPORT:
      return {
        ...state,
        dvr: {
          ...state.dvr,
          [action.tabKey]: {
            ...state.dvr[action.tabKey],
            loading: true,
          },
        },
      };
    case DVR_REPORT_SUCCESS:
      return {
        ...state,
        dvr: {
          ...state.dvr,
          [action.tabKey]: {
            ...state.dvr[action.tabKey],
            loading: false,
            ...action.payload,
          },
        },
      };
    case DVR_REPORT_FAIL:
      return {
        ...state,
        dvr: {
          ...state.dvr,
          [action.tabKey]: {
            ...state.dvr[action.tabKey],
            loading: false,
            error: action.payload,
          },
        },
      };
    case DVR_ROWS_INFO:
      return {
        ...state,
        dvr: {
          ...state.dvr,
          [action.tabKey]: {
            ...state.dvr[action.tabKey],
            rowsInfo: action.payload,
          },
        },
      };
    case SHIPMENTS:
      return {
        ...state,
        shipments: {
          ...state.shipments,
          [action.tabKey]: {
            ...state.shipments[action.tabKey],
            loading: true,
          },
        },
      };
    case SHIPMENTS_SUCCESS:
      return {
        ...state,
        shipments: {
          ...state.shipments,
          [action.tabKey]: {
            ...state.shipments[action.tabKey],
            loading: false,
            ...action.payload,
          },
        },
      };
    case SHIPMENTS_FAIL:
      return {
        ...state,
        shipments: {
          ...state.shipments,
          [action.tabKey]: {
            ...state.shipments[action.tabKey],
            loading: false,
            error: action.payload,
          },
        },
      };
    case SHIPMENTS_ROWS_INFO:
      return {
        ...state,
        shipments: {
          ...state.shipments,
          [action.tabKey]: {
            ...state.shipments[action.tabKey],
            rowsInfo: action.payload,
          },
        },
      };
    case SHIPMENTS_HISTORY:
      return {
        ...state,
        shipmentsHistory: {
          ...state.shipmentsHistory,
          [action.tabKey]: {
            ...state.shipmentsHistory[action.tabKey],
            loading: true,
          },
        },
      };
    case SHIPMENTS_HISTORY_SUCCESS:
      return {
        ...state,
        shipmentsHistory: {
          ...state.shipmentsHistory,
          [action.tabKey]: {
            ...state.shipmentsHistory[action.tabKey],
            loading: false,
            ...action.payload,
          },
        },
      };
    case SHIPMENTS_HISTORY_FAIL:
      return {
        ...state,
        shipmentsHistory: {
          ...state.shipmentsHistory,
          [action.tabKey]: {
            ...state.shipmentsHistory[action.tabKey],
            loading: false,
            error: action.payload,
          },
        },
      };
    case SHIPMENTS_HISTORY_ROWS_INFO:
      return {
        ...state,
        shipmentsHistory: {
          ...state.shipmentsHistory,
          [action.tabKey]: {
            ...state.shipmentsHistory[action.tabKey],
            rowsInfo: action.payload,
          },
        },
      };
    case DESTROY_VCI_BY_KEY:
      delete state[action.type][action.tabKey];
      return state;
    case SET_FOCUSED_CELL_EDITOR:
      return {
        ...state,
        shipments: {
          ...state.shipments,
          [action.tabKey]: {
            ...state.shipments[action.tabKey],
            focusedCell: {
              colId: action.colId,
              rowIndex: action.rowIndex,
            },
          },
        },
      };
    case SET_PICKER_INFO_VCI:
      return {
        ...state,
        shipments: {
          ...state.shipments,
          [action.tabKey]: {
            ...state.shipments[action.tabKey],
            datePicker: {
              ...action.payload,
            },
          },
        },
      };
    default:
      return state;
  }
}

const getQsProps = (tabKey) => {
  const qsState = store.getState().pc.search.quickSearch;
  const tabData = qsState[tabKey] || {};

  const stringify = (val = '') => {
    if (typeof val !== 'string') {
      const filteredVal = val.filter((data) => data !== 'all');
      return filteredVal.join();
    } else {
      return val;
    }
  };

  return {
    customer_plant: stringify(tabData.customerPlant),
    supplier_name: stringify(tabData.supplierName),
    supplier_code: stringify(tabData.supplierCode),
    supplier_key: stringify(tabData.supplierDepot),
    site_name: stringify(tabData.supplierSite),
    search_term: tabData.searchTerm,
  };
};

function getDvrColumns(tabKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: DVR_REPORT, tabKey });
    const currentTime = getEpochTime();
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'AG_GRID_COLUMNS',
            view: `VCI_DVR`,
            details: {
              email: getEmail(),
            },
          },
          getState
        )
      )
      .then((response) => {
        gaPageviewTimingTitle(`/CDS/GET_VCI_DVR_AG_GRID_COLUMNS`, currentTime);
        const { data } = response;
        if (data && data.statusCode === '200') {
          const { columnDefs, ...configs } = data.result;

          let dateFields = [];
          const columnDefsModified = columnDefs.map((columnDef) => {
            if (columnDef.type === 'date') {
              dateFields = [...dateFields, columnDef.colId];
              return {
                ...columnDef,
                floatingFilterComponent: 'agCustomDateFloatingFilter',
                filter: 'agCustomDateFilter',
              };
            }
            return columnDef;
          });
          const configDetails = {
            ...configs,
            columnDefs: columnDefsModified,
            dateFields,
          };

          dispatch({
            type: DVR_REPORT_SUCCESS,
            payload: configDetails,
            tabKey,
          });
        } else {
          dispatch({
            type: DVR_REPORT_FAIL,
            payload: 'Error in loading daily vendor report!',
            tabKey,
          });
        }
      });
  };
}

function getDvrRows(tabKey) {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      const currentTime = getEpochTime();
      return api
        .post(
          'pc',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: `VCI_DVR`,
              details: {
                email: getEmail(),
                ...getQsProps(tabKey),
              },
              gridParams: { ...params.request },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(`/CDS/GET_VCI_DVR_AG_GRID_ROWS`, currentTime);

          const { data } = response;

          if (data && data.statusCode === '200') {
            const result = data.result;
            params.success({
              rowData: result.rowData,
              rowCount: getLastRowIndex(params.request, result.rowData),
            });
            const endRow =
              params.request.endRow > result.lastRow
                ? result.lastRow
                : params.request.endRow;
            store.dispatch({
              type: DVR_ROWS_INFO,
              payload: { lastRow: result.lastRow, endRow },
              tabKey,
            });
            // Hide overlay loading on success
            params.api.hideOverlay();
            if (!result.rowData.length) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch((_error) => {
          params.fail();
        });
    },
  };
}

function getShipmentColumns(tabKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: SHIPMENTS, tabKey });
    const currentTime = getEpochTime();
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'AG_GRID_COLUMNS',
            view: `VCI_SHIPMENTS`,
            details: {
              email: getEmail(),
            },
          },
          getState
        )
      )
      .then((response) => {
        gaPageviewTimingTitle(
          `/CDS/GET_VCI_SHIPMENTS_VCI_SHIPMENTS`,
          currentTime
        );
        const { data } = response;
        if (data && data.statusCode === '200') {
          data.result.columnDefs.unshift(selectColumn);

          const { columnDefs, ...configs } = data.result;
          let dateFields = [];
          const columnDefsModified = columnDefs.map((columnDef) => {
            if (columnDef.type === 'date') {
              dateFields = [...dateFields, columnDef.colId];
              return {
                ...columnDef,
                floatingFilterComponent: 'agCustomDateFloatingFilter',
                filter: 'agCustomDateFilter',
              };
            }
            return columnDef;
          });
          const configDetails = {
            ...configs,
            columnDefs: columnDefsModified,
            dateFields,
          };

          dispatch({
            type: SHIPMENTS_SUCCESS,
            payload: configDetails,
            tabKey,
          });
        } else {
          dispatch({
            type: SHIPMENTS_FAIL,
            payload: 'Error in loading smi pulls!',
            tabKey,
          });
        }
      });
  };
}

function getShipmentRows(tabKey) {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      const currentTime = getEpochTime();
      return api
        .post(
          'pc',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: `VCI_SHIPMENTS`,
              gridParams: { ...params.request },
              details: {
                email: getEmail(),
                ...getQsProps(tabKey),
              },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(
            `/CDS/GET_VCI_SHIPMENTS_AG_GRID_ROWS`,
            currentTime
          );

          const { data } = response;
          if (data && data.statusCode === '200') {
            let rowData = data.result.rowData.map((row, index) => {
              return {
                ...row,
                isEditing: false,
                errors: {},
                hasError: false,
                originalValues: { ...row },
              };
            });
            params.success({
              rowData,
              rowCount: getLastRowIndex(params.request, rowData),
            });
            const endRow =
              params.request.endRow > data.result.lastRow
                ? data.result.lastRow
                : params.request.endRow;
            store.dispatch({
              type: SHIPMENTS_ROWS_INFO,
              payload: { lastRow: data.result.lastRow, endRow },
              tabKey,
            });
            // Hide overlay loading on success
            params.api.hideOverlay();
            const rowsPerRequest = process.env.REACT_APP_AG_GRID_ROWS_LIMIT;
            if (!rowData.length && params.request.startRow < rowsPerRequest) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch((error) => {
          params.fail();
        });
    },
  };
}

const isNull = (val) =>
  val == null || val == '' || val == undefined ? null : val;

function prepareAndSubmitData(nodes, gridApi, t) {
  if (nodes.length) {
    let asnParts = [];

    nodes.map(({ data }) => {
      asnParts.push({
        carrier: data.carriers,
        customer_plant: data.customer_plant,
        expt_receipt_date: isNull(data.expected_recept_date),
        line_number: isNull(data.shipment_line),
        net_weight: isNull(data.net_weight),
        packing_slip: isNull(data.packing_slip),
        part: data.part,
        po_number: data.bpo_number,
        po_release: data.po_release,
        qty: isNull(data.asn_qty),
        service_level: isNull(data.service_level),
        ship_date: isNull(data.shipment_date),
        ship_method: isNull(data.ship_method),
        shipment_no: isNull(data.shipment_no),
        supplier_key: data.supplier_key,
        uom: data.uom,
        waybill_number: isNull(data.way_bill_no),
        weight_units: isNull(data.weight_units),
      });
    });

    return (_dispatch, _getState, { api }) => {
      return api
        .post('pcsubmitaction', {
          TXN: {
            Header: {
              customer: 'SANM',
              excel: 'NO',
              requestor: Cookie.get('email'),
              source: 'PORTAL',
              txn_type: '856VCI',
              uuid: getUUID(),
            },
            ASNPartArray: asnParts,
          },
        })
        .then((response) => {
          const { data } = response;
          if (data.TXN[0].return_msg == 'SUCCESS') {
            message.success(t('data_updated_successfully'));
            //Refresh grid
            gridApi.stopEditing();
            nodes.map((node) => {
              node.setSelected(false);
              node.updateData({
                ...node.data,
                isEditing: false,
                errors: {},
                hasError: false,
              });
            });
            gridApi.refreshServerSide({ purge: true });
            gridApi.refreshCells({ force: true });
            gridApi.refreshHeader();
          } else {
            const failureResponse = data.TXN[0].ASNPartArray;
            Modal.error({
              title: t('failed_transactions'),
              icon: <ExclamationCircleOutlined />,
              width:'52%',
              content: (
                <div
                  style={{
                    maxHeight: 250,
                    minHeight: 100,
                    overflowY: 'auto',
                  }}>
                  <div
                    style={{
                      borderBottom: '1px solid #CCC',
                      padding: 5,
                      fontSize: 12,
                      fontWeight: 'bold',
                    }}>
                    <Row>
                      <Col style={{ textAlign: 'center' }} span={4} order={1}>
                        Plant
                      </Col>
                      <Col style={{ textAlign: 'center' }} span={5} order={2}>
                        Supplier
                      </Col>
                      <Col style={{ textAlign: 'center' }} span={7} order={3}>
                        Part
                      </Col>
                      <Col style={{ textAlign: 'center' }} span={8} order={4}>
                        Error
                      </Col>
                    </Row>
                  </div>
                  {failureResponse[0].map((item) => (
                    <div
                      key={getUUID()}
                      style={{
                        borderBottom: '1px solid #CCC',
                        padding: 5,
                        fontSize: 10,
                      }}>
                      <Row>
                        <Col style={{ textAlign: 'center' }} span={5} order={1}>
                          {item.customer_plant}
                        </Col>
                        <Col style={{ textAlign: 'center' }} span={4} order={2}>
                          {item.supplier_key}
                        </Col>
                        <Col style={{ textAlign: 'center' }} span={7} order={3}>
                          {item.part}
                        </Col>
                        <Col
                          style={{
                            textAlign: 'center',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                          span={8}
                          order={4}>
                          <span title={item.error_message}>
                            {item.error_message}
                          </span>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </div>
              ),
            });
          }
          return response
        });
    };
  }
}

function getShipmentHistColumns(tabKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: SHIPMENTS_HISTORY, tabKey });
    const currentTime = getEpochTime();
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'AG_GRID_COLUMNS',
            view: `VCI_SHIPMENTS_HISTORY`,
            details: {
              email: getEmail(),
            },
          },
          getState
        )
      )
      .then((response) => {
        gaPageviewTimingTitle(
          `/CDS/GET_VCI_SHIPMENTS_HISTORY_AG_GRID_COLUMNS`,
          currentTime
        );
        const { data } = response;
        if (data && data.statusCode === '200') {
          const { columnDefs, ...configs } = data.result;
          let dateFields = [];
          const columnDefsModified = columnDefs.map((columnDef) => {
            if (columnDef.type === 'date') {
              dateFields = [...dateFields, columnDef.colId];
              return {
                ...columnDef,
                floatingFilterComponent: 'agCustomDateFloatingFilter',
                filter: 'agCustomDateFilter',
              };
            }
            return columnDef;
          });
          const configDetails = {
            ...configs,
            columnDefs: columnDefsModified,
            dateFields,
          };
          dispatch({
            type: SHIPMENTS_HISTORY_SUCCESS,
            payload: configDetails,
            tabKey,
          });
        } else {
          dispatch({
            type: SHIPMENTS_HISTORY_FAIL,
            payload: 'Error in loading daily vendor report!',
            tabKey,
          });
        }
      });
  };
}

function getShipmentHistRows(tabKey) {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      const currentTime = getEpochTime();
      return api
        .post(
          'pc',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: `VCI_SHIPMENTS_HISTORY`,
              gridParams: { ...params.request },
              details: {
                email: getEmail(),
                ...getQsProps(tabKey),
              },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(
            `/CDS/GET_VCI_SHIPMENTS_HISTORY_AG_GRID_ROWS`,
            currentTime
          );
          const { data } = response;
          if (data && data.statusCode === '200') {
            let rowData = data.result.rowData.map((row, index) => {
              return {
                ...row,
                isEditing: false,
                errors: {},
                hasError: false,
              };
            });
            params.success({
              rowData,
              rowCount: getLastRowIndex(params.request, rowData),
            });
            const endRow =
              params.request.endRow > data.result.lastRow
                ? data.result.lastRow
                : params.request.endRow;
            store.dispatch({
              type: SHIPMENTS_HISTORY_ROWS_INFO,
              payload: { lastRow: data.result.lastRow, endRow },
              tabKey,
            });
            // Hide overlay loading on success
            params.api.hideOverlay();
            const rowsPerRequest = process.env.REACT_APP_AG_GRID_ROWS_LIMIT;
            if (!rowData.length && params.request.startRow < rowsPerRequest) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch((error) => {
          params.fail();
        });
    },
  };
}

function getVciHeaderDetails(nodeData, cb) {
  return (_dispatch, getState, { api, formatParams }) => {
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'VIEW_VCI_HEADER',
            view: 'VCI',
            details: {
              email: getEmail(),
              partname: nodeData[`part`],
              supplierkey: nodeData[`supplier_key`],
              customercode: nodeData[`customer_code`] || nodeData[`customer`],
              customerplant: nodeData[`customer_plant`],
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (isObject(data)) {
          cb(data);
        }
      });
  };
}

const commentUpdate = (txnData, t) => {
  return (_dispatch, _getState, { api }) => {
    return api
      .post('pcsubmitaction', {
        TXN: {
          Header: {
            customer: 'SANM',
            requestor: Cookie.get('email'),
            source: 'PORTAL',
            txn_type: 'COMMENT_UPDATE',
            uuid: getUUID(),
          },
          PartsArray: txnData,
        },
      })
      .then((response) => {
        if (response.data?.TXN) {
          let txn = response.data.TXN[0];
          if (txn.return_msg === 'SUCCESS') {
            message.success(t('data_saved_successfully'));
          } else {
            message.error(t('something_went_wrong'));
          }
        }
      });
  };
};

const getDvrQtyPopupDetails = (category, nodeData, callBack) => {
  return (_dispatch, _getState, { api, formatParams }) => {
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'VCI_QTY_POPUP_DETAILS',
            view: 'VCI',
            details: {
              email: getEmail(),
              category,
              customer: nodeData[`customer`],
              customer_plant: nodeData[`customer_plant`],
              part: nodeData[`part`],
              inv_date: nodeData[`inv_date`],
              supplier_key: nodeData[`supplier_key`],
            },
          },
          store.getState
        )
      )
      .then((response) => {
        callBack(response);
      });
  };
};

const getDailyConsumptionReport = ({
  fromDate,
  toDate,
  gridApi,
  columnApi,
  tabKey,
  callBack,
}) => {
  return (_dispatch, _getState, { api, formatParams }) => {
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'VCI_CONSUMPTION_REPORT',
            gridParams: {
              ...getMassDownloadGridParams(gridApi, columnApi),
            },
            details: {
              email: getEmail(),
              from_date: fromDate,
              to_date: toDate,
              ...getQsProps(tabKey),
            },
          },
          store.getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data.statusCode === '200' && isObject(data.result)) {
          callBack(data.result);
        }
      });
  };
};

function getMassDownloadReport({
  gridApi,
  exportType,
  totalRows,
  cb,
  columnApi,
  fileName,
  view,
  tabKey,
  columnsExclude,
}) {
  return (_dispatch, getState, { api, formatParams }) => {
    if (totalRows >= process.env.REACT_APP_MASS_DOWNLOAD_ENABLE_LIMIT) {
      return api
        .post(
          'pc',
          formatParams(
            {
              type: 'MASS_DOWNLOAD',
              view: view,
              exportType,
              totalRows,
              email: getEmail(),
              details: {
                email: getEmail(),
                ...getQsProps(tabKey),
              },
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
              },
            },
            getState
          )
        )
        .then((response) => {
          cb(true);
          return response;
        })
        .catch((error) => {
          cb(false);
          return error;
        });
    } else {
      const currentTime = getEpochTime();
      return api
        .post(
          'pc',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: view,
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
                startRow: 0,
                endRow: totalRows,
              },
              details: {
                email: getEmail(),
                ...getQsProps(tabKey),
              },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(
            `/CDS/GET_${view}_MASS_DOWNLOAD_REPORT`,
            currentTime
          );
          const { data } = response;
          if (data) {
            const { result } = data;
            if (Object.keys(result).length) {
              exportDTo({
                columnApi,
                fileName: fileName,
                data: result.rowData,
                exportType,
                columnsExclude,
              }) && cb(true);
            }
          }

          return response;
        });
    }
  };
}

const setFocusedCell = (colId, rowIndex, tabKey) => {
  return (dispatch) => {
    dispatch({ type: SET_FOCUSED_CELL_EDITOR, colId, rowIndex, tabKey });
  };
};

const setPickerInfo = (payload, tabKey) => {
  return (dispatch) => {
    dispatch({
      type: SET_PICKER_INFO_VCI,
      payload,
      tabKey,
    });
  };
};

export {
  getDvrColumns,
  getDvrRows,
  getShipmentColumns,
  getShipmentRows,
  getShipmentHistColumns,
  getShipmentHistRows,
  getMassDownloadReport,
  prepareAndSubmitData,
  commentUpdate,
  getVciHeaderDetails,
  getDvrQtyPopupDetails,
  getDailyConsumptionReport,
  setFocusedCell,
  setPickerInfo,
};
