import { isDevInstance } from 'helpers/utils';

const buff_to_base64 = (buff) =>
  btoa(
    new Uint8Array(buff).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ''
    )
  );

const enc = new TextEncoder();

const getPasswordKey = (password) =>
  window.crypto.subtle.importKey('raw', enc.encode(password), 'PBKDF2', false, [
    'deriveKey',
  ]);

const deriveKey = (passwordKey, salt, keyUsage) =>
  window.crypto.subtle.deriveKey(
    {
      name: 'PBKDF2',
      salt: salt,
      iterations: 100000,
      hash: 'SHA-256',
    },
    passwordKey,
    { name: 'AES-GCM', length: 256 },
    false,
    keyUsage
  );

async function encryptData(password, secretData) {
  try {
    const salt = window.crypto.getRandomValues(new Uint8Array(16));
    const iv = window.crypto.getRandomValues(new Uint8Array(16));
    const passwordKey = await getPasswordKey(secretData);
    const aesKey = await deriveKey(passwordKey, salt, ['encrypt']);
    const encryptedContent = await window.crypto.subtle.encrypt(
      {
        name: 'AES-GCM',
        iv: iv,
      },
      aesKey,
      enc.encode(password)
    );

    const encryptedContentArr = new Uint8Array(encryptedContent);
    let buff = new Uint8Array(
      salt.byteLength + iv.byteLength + encryptedContentArr.byteLength
    );
    buff.set(salt, 0);
    buff.set(iv, salt.byteLength);
    buff.set(encryptedContentArr, salt.byteLength + iv.byteLength);
    const base64Buff = buff_to_base64(buff);
    return base64Buff;
  } catch (e) {
    if (isDevInstance()) {
      console.log(`Error - ${e}`);
    }
    return '';
  }
}

async function encrypt(pwd) {
  const skey = localStorage.getItem('skey') || '';
  const parsedKey = JSON.parse(skey);
  if (parsedKey && parsedKey !== '') {
    const pwdEncoded = await encryptData(pwd, parsedKey);
    return pwdEncoded;
  }

  return pwd;
}

export { encrypt };
