import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Menu, Spin } from 'antd';
import Icon, {
  DashboardOutlined,
  PlusOutlined,
  AppstoreOutlined,
  FolderOpenOutlined,
  CiOutlined,
  FileExcelOutlined,
  BarChartOutlined,
  AreaChartOutlined,
  UsergroupAddOutlined,
  HistoryOutlined,
  TrademarkOutlined,
  QuestionCircleOutlined,
  CheckSquareOutlined,
  SelectOutlined,
  InfoCircleOutlined,
  FileSyncOutlined,
  ReconciliationOutlined,
  LineChartOutlined,
  ShoppingCartOutlined,
  ScheduleOutlined,
  SettingOutlined,
  DownloadOutlined,
  FormOutlined,
  FileSearchOutlined,
} from '@ant-design/icons';
import { getExcelTemplateList } from '../../acp/excel-template/redux/excel-template';
import ExcelTemplate from '../../acp/excel-template/ExcelTemplate';
import PropTypes from 'prop-types';
import { useTranslation } from 'hooks';

const { SubMenu } = Menu;

const siderIcons = {
  DashboardOutlined,
  PlusOutlined,
  AppstoreOutlined,
  FolderOpenOutlined,
  CiOutlined,
  FileExcelOutlined,
  BarChartOutlined,
  AreaChartOutlined,
  UsergroupAddOutlined,
  HistoryOutlined,
  TrademarkOutlined,
  QuestionCircleOutlined,
  CheckSquareOutlined,
  SelectOutlined,
  InfoCircleOutlined,
  FileSyncOutlined,
  ReconciliationOutlined,
  LineChartOutlined,
  ShoppingCartOutlined,
  ScheduleOutlined,
  SettingOutlined,
  DownloadOutlined,
  FormOutlined,
  FileSearchOutlined,
};

const Sider = (props) => {
  const tourReferences = useSelector(({ tour }) => tour.tourReferences);
  // Use the below state and set state for Download Excel Template Functionality
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();
  //  Use this "excelTemplateData" variable to access the reducer (api response from the service) for the Download Excel Template Functionality
  const { excelTemplateData, defaultAccess } = useSelector(
    ({ acp: { excelTemplate }, authorization }) => ({
      excelTemplateData: excelTemplate.data,
      defaultAccess: authorization.initAuthorization.defaultAccess,
    })
  );

  const isSiderLoading = useSelector(({ layout }) => layout.siderLoading);

  const navigate = useNavigate();

  const { t, isTranslationReady } = useTranslation();

  const showexceltemplate = () => {
    dispatch(getExcelTemplateList()).then(() => {
      setIsModalVisible(true);
    });
  };

  const getMenuLabel = ({ name, label }) => {
    if (defaultAccess === 'PC') {
      return t(`${name.toLowerCase()}_screen`);
    }

    return label;
  };

  const getIcon = (menu) => {
    if (defaultAccess == 'PC') {
      const iconNames = [
        'PARTS',
        'FC',
        'RTF',
        'SMI',
        'ASN',
        'VCI',
        'INV',
        'NBS',
        'RES',
      ];
      if (iconNames.includes(menu.name)) {
        return <Icon className='text-icon' component={() => menu.name} />;
      }
    }

    return <Icon component={siderIcons[menu.icon]} />;
  };

  const buildMenuItem = (menu) => {
    if (menu.tab) {
      return (
        <span
          className={menu.label.replace(' ', '_')}
          ref={getTourRefs(menu.name)}>
          {getIcon(menu)}
          <span className={menu.label.replace(' ', '_')}>
            {getMenuLabel(menu)}
          </span>
        </span>
      );
    } else if (menu.name === 'EXCEL_DOWNLOAD') {
      return (
        <span
          className={menu.label.replace(' ', '_')}
          ref={tourReferences.excelDownload}>
          {/*We call this showexceltemplate to display the download excel template popup  */}
          {getIcon(menu)}
          <span className={menu.label.replace(' ', '_')}>
            {getMenuLabel(menu)}
          </span>
        </span>
      );
    } else {
      return (
        <span className={menu.label.replace(' ', '_')}>
          {getIcon(menu)}
          <span className={menu.label.replace(' ', '_')}>
            {getMenuLabel(menu)}
          </span>
        </span>
      );
    }
  };

  const getTourRefs = (menuItem) => {
    switch (menuItem) {
      case 'Dashboard':
        return tourReferences.adminCdsDashboard;
      case 'New_Report':
        return tourReferences.newReportCdsTour;
      case 'EXPORT_HISTORY':
        return tourReferences.exportHistoryCdsTour;
      case 'REPORT_SETTINGS':
        return tourReferences.reportSettingsCdsTour;
      case 'REPORT_HISTORY':
        return tourReferences.reportHistoryCdsTour;
      case 'ORDER_STATUS':
        return tourReferences.orderStatusCdsTour;
      case 'ORDER_HISTORY':
        return tourReferences.orderHistoryCdsTour;
      case 'EXCEL_DOWNLOAD':
        return tourReferences.excelDownload;
      case 'CANDIDATE_ITEM':
        return tourReferences.candidateItem;
      case 'PARTS':
        return tourReferences.pcParts;
      case 'FC':
        return tourReferences.pcFc;
      case 'RTF':
        return tourReferences.pcRtf;
      case 'SMI':
        return tourReferences.pcSmi;
      case 'ASN':
        return tourReferences.pcAsn;
      case 'VCI':
        return tourReferences.pcVci;
      case 'INV':
        return tourReferences.pcInv;
      case 'NBS':
        return tourReferences.pcNbs;
      case 'RES':
        return tourReferences.pcRes;
      default:
        return null;
    }
  };

  const siderItem = (options) => {
    const menuArr = [];
    options.map((menu) => {
      if (menu.submenu) {
        menuArr.push(
          <SubMenu
            key={menu.name}
            title={
              <span
                className={menu.label.replace(' ', '_')}
                ref={getTourRefs(menu.name)}>
                {getIcon(menu)}
                <span>{getMenuLabel(menu)}</span>
              </span>
            }>
            {menu.childrens.length ? siderItem(menu.childrens) : null}
          </SubMenu>
        );
      } else {
        menuArr.push(
          <Menu.Item key={`${menu.name}`}>{buildMenuItem(menu)}</Menu.Item>
        );
      }
    });

    return menuArr;
  };

  const getLoadingElement = () => [
    <div className='center-align-loader' key={'loading'}>
      <Spin size={'small'} tip='Fetching menus...'>
        <div
          style={{
            padding: 40,
            borderRadius: 4,
          }}
        />
      </Spin>
    </div>,
  ];

  const flattenMenuItems = () => {
    const menuItems = [];

    const addMenuItem = (items) => {
      items.forEach((item) => {
        if (!item.childrens.length && item.tab) {
          menuItems.push(item);
        } else {
          addMenuItem(item.childrens);
        }
      });
    };

    addMenuItem(props.menuOptions);

    return menuItems;
  };

  const handleMenuClick = (item) => {
    const menuItemKey = item.key;
    if (menuItemKey.toLowerCase() === 'dashboard') {
      props.handlePageClick(menuItemKey);
      navigate('/dashboard');
    } else if (menuItemKey === 'EXCEL_DOWNLOAD') {
      showexceltemplate();
    } else {
      const menuList = flattenMenuItems();
      const { name, label } = menuList.find(
        (menu) => menu.name === menuItemKey
      );
      props.addTab(`${name}`, getMenuLabel({ name, label }));
    }
  };

  return (
    <>
      <Menu
        theme={props.theme}
        mode={props.mode}
        defaultSelectedKeys={[props.defaultSelectedKeys]}
        onClick={handleMenuClick}
        selectedKeys={[props.defaultSelectedKeys]}>
        {/** Function should return same type - Sonar Scan Rule */}
        {(() => {
          let siderItems = null;
          if (
            props.authorizationRequest ||
            isSiderLoading ||
            !isTranslationReady
          ) {
            siderItems = getLoadingElement();
          }

          if (props.menuOptions?.length) {
            siderItems = siderItem(props.menuOptions);
          }

          return siderItems;
        })()}
      </Menu>
      {/* Use the Component to display the Download Excel Template popup, here we are passing the visible and data for that component */}
      <ExcelTemplate
        visible={isModalVisible}
        data={excelTemplateData}
        handlevisiblity={setIsModalVisible}
      />
    </>
  );
};

Sider.propTypes = {
  addTab: PropTypes.func,
  handlePageClick: PropTypes.func,
  mode: PropTypes.string,
  defaultSelectedKeys: PropTypes.array,
  authorizationRequest: PropTypes.bool,
  menuOptions: PropTypes.array,
  theme: PropTypes.string,
};

export default Sider;
