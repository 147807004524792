import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import { Select } from 'antd';

dayjs.extend(weekday);
dayjs.extend(localeData);

const { Option } = Select;

const getObjectValue = (obj, key) => {
  return obj?.[key] || {};
};

const getLovData = (moduleData, module) => moduleData[`${module}Lov`] || {};

const getHolidays = (lovList, props) =>{
  if(props.node.data.group) {
   return  lovList['holidays']
   ? lovList['holidays'][props.node.data.customer_plant]
   : [];
  }
  else {
   return  lovList['holidays']
   ? lovList['holidays'][props.node.parent.data.customer_plant]
   : [];
 }
}
const getFormattedDate = (val) => (val ? dayjs(val, 'YYYY-MM-DD') : '');

const getFormattedDateStr = (date) =>
  date ? dayjs(date).format('YYYY-MM-DD') : '';

const isPastDate = (current, serverDate) => {
  return current < dayjs(serverDate).startOf('day');
};

const isPlantHoliday = (current, holidays) =>
  holidays?.includes(getFormattedDateStr(current));

const isCrossedHorizonEndDate = (current, horizonEndDate) =>
  dayjs(current) > horizonEndDate;

const getDisabledDates = (current, holidays, horizonEndDate, serverDate) => {
  return (
    isPastDate(current, serverDate) ||
    isPlantHoliday(current, holidays) ||
    isCrossedHorizonEndDate(current, horizonEndDate)
  );
};

const getMandatoryClass = (requiredColumns, colId) => {
  return requiredColumns?.includes(colId) ? 'om-mandatory-col' : '';
};

const getErrorDesc = (errorDesc) => {
  return (
    <div style={{ fontSize: 12 }}>
      {typeof errorDesc === 'string' ? errorDesc : errorDesc[0]}
    </div>
  );
};

const getLovList = (lovList, colId, props) =>
  lovList[colId] ? lovList[colId][props.node.data.customer_plant] : [];

const getMandatoryClassSelect = (requiredColumns, colId) => {
  return requiredColumns?.includes(colId) ? 'om-mandatory-select-col' : '';
};

const showLovList = (lov) =>
  lov?.length
    ? lov.map((option) => (
        <Option key={option} value={option}>
          {option}
        </Option>
      ))
    : null;

const getLovListValue = (lovList, colId) =>
  lovList[colId] ? lovList[colId] : [];

const getDefaultValue = (val) =>
  val !== '' && val !== 'Cancel' ? getFormattedDate(val) : '';

const isParentEditable = (props, colId, parentExcludeEditable) =>
  props.node.data.group && !parentExcludeEditable.includes(colId);

const isLeafEditable = (props, colId, leafEditables) =>
  !props.node.data.group && leafEditables.includes(colId);

const isCellHasErrors = (errors, colId) =>
  errors && errors[colId] !== undefined;

const setAgEditCount = ({
  tabKey,
  reduce,
  colId,
  rowIndex,
  reset,
  resetCount,
}) => {
  const gridEditCount = localStorage.getItem('gridEditCount');
  if (gridEditCount) {
    let gridEditCountParse =
      gridEditCount === '[object Object]' ? {} : JSON.parse(gridEditCount);
    let colValue = parseInt(
      gridEditCountParse[tabKey]?.[`${colId}_${rowIndex}`]
    );

    const increment = () => colValue + 1;
    const decrement = () => colValue - 1;

    if (reset) {
      localStorage.setItem('gridEditCount', {
        ...gridEditCountParse,
        [tabKey]: {},
      });
      return;
    }

    if (resetCount) {
      localStorage.setItem(
        'gridEditCount',
        JSON.stringify({
          ...gridEditCountParse,
          [tabKey]: {
            ...gridEditCountParse[tabKey],
            [`${colId}_${rowIndex}`]: 0,
          },
        })
      );
      return;
    }

    if (reduce && !isNaN(colValue) && colValue > 0) {
      localStorage.setItem(
        'gridEditCount',
        JSON.stringify({
          ...gridEditCountParse,
          [tabKey]: {
            ...gridEditCountParse[tabKey],
            [`${colId}_${rowIndex}`]: decrement(),
          },
        })
      );
    } else {
      localStorage.setItem(
        'gridEditCount',
        JSON.stringify({
          ...gridEditCountParse,
          [tabKey]: {
            ...(gridEditCountParse[tabKey] ? gridEditCountParse[tabKey] : {}),
            [`${colId}_${rowIndex}`]:
              gridEditCountParse[tabKey]?.[`${colId}_${rowIndex}`] === undefined
                ? 1
                : increment(),
          },
        })
      );
    }
  }
};

const isAgGridEdited = (tabKey) => {
  const gridEditCount = localStorage.getItem('gridEditCount');
  if (gridEditCount && gridEditCount !== '[object Object]') {
    const gridEditDetails = JSON.parse(gridEditCount);
    const editObj = gridEditDetails[tabKey];
    if (editObj && Object.getOwnPropertyNames(editObj).length) {
      return Object.keys(editObj)
        .map((key) => editObj[key])
        .some((value) => parseInt(value) !== 0);
    }
  }
  return false;
};

export {
  getObjectValue,
  getLovData,
  getHolidays,
  getFormattedDate,
  isPastDate,
  getDisabledDates,
  getMandatoryClass,
  getErrorDesc,
  getLovList,
  getMandatoryClassSelect,
  showLovList,
  getLovListValue,
  getDefaultValue,
  isParentEditable,
  isLeafEditable,
  isCellHasErrors,
  getFormattedDateStr,
  setAgEditCount,
  isAgGridEdited,
};
