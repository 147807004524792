import { getUUID } from 'helpers/utils';
import { List } from 'antd';

const getSelectedCriteria = (tabData) => {
  return {
    Plant: tabData.customerPlant || [],
    'Supplier Name': tabData.supplierName || [],
    'Supplier Code': tabData.supplierCode || [],
    'Supplier Site': tabData.supplierSite || [],
    'Supplier Depot': tabData.supplierDepot || [],
    'Agility Type': tabData.agilityType || [],
    Part: tabData.partName || [],
    'Search Term': tabData.searchTerm || '',
    'View Type': tabData.viewType || '',
  };
};

const convertStringtoArrayObj = (data) => {
  if (data !== undefined) {
    if (typeof data === 'string') {
      return data?.trim() !== '' ? [data] : [];
    } else {
      return data;
    }
  } else {
    return [];
  }
};

const isUnsavedSearchExists = (tabData) => {
  const customerPlant = convertStringtoArrayObj(tabData?.customerPlant);
  const supplierName = convertStringtoArrayObj(tabData?.supplierName);
  const supplierCode = convertStringtoArrayObj(tabData?.supplierCode);
  const supplierSite = convertStringtoArrayObj(tabData?.supplierSite);
  const supplierDepot = convertStringtoArrayObj(tabData?.supplierDepot);
  const agilityType = convertStringtoArrayObj(tabData?.agilityType);
  if (
    customerPlant.length > 0 ||
    supplierName.length > 0 ||
    supplierCode.length > 0 ||
    supplierSite.length > 0 ||
    supplierDepot.length > 0 ||
    agilityType.length > 0
  ) {
    return true;
  }
  return false;
};

const stringify = (val) => {
  const filteredVal = val.filter((data) => data !== 'all');
  return filteredVal.join('~');
};

const isCriteriaExists = (tabData) => {
  const criteria = getSelectedCriteria(tabData);
  return criteria.Plant.length;
};

const showCriteria = (tabData, defaultAccess) =>
  defaultAccess === 'PC' &&
  isCriteriaExists(tabData) &&
  (tabData.qsModalApplied || tabData.qsApplied);

const getCriteriaStyle = (view) => ({
  ...(view === 'dashboard'
    ? { marginLeft: 5, lineHeight: '10px', marginBottom: 5 }
    : { marginBottom: 5, lineHeight: '10px' }),
});

const getNonStrCriteria = (criteria, key, cbCriList, cbCriArr) => {
  if (criteria[key].length > 0 && criteria?.[key]?.[0] !== '') {
    cbCriArr([
      <strong key={getUUID()}>{key}: </strong>,
      <span key={getUUID()}>{stringify(criteria[key])}; </span>,
    ]);
    cbCriList(
      <List
        size='small'
        style={{ fontSize: 12 }}
        header={key}
        dataSource={[stringify(criteria[key])]}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
    );
  }
};

const getStrCriteria = (criteria, key, cbCriList, cbCriArr) => {
  if (criteria[key].length > 0) {
    cbCriArr([
      <strong key={getUUID()}>{key}: </strong>,
      <span key={getUUID()}>{criteria[key]}; </span>,
    ]);
    cbCriList(
      <List
        size='small'
        style={{ fontSize: 12 }}
        header={key}
        dataSource={[criteria[key]]}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
    );
  }
};

const getObjectValue = (search, tabKey) => search[tabKey] || {};

export {
  getSelectedCriteria,
  stringify,
  isCriteriaExists,
  showCriteria,
  getCriteriaStyle,
  getStrCriteria,
  getNonStrCriteria,
  getObjectValue,
  isUnsavedSearchExists,
};
