import React, { useState, useEffect } from 'react';
import { Checkbox } from 'antd';
import PropTypes from 'prop-types';

const CustomHeaderCheckboxRenderer = (props) => {
  const getTotalRowCount = () => {
    let totalRows = 0;
    props.api.forEachNode((_node) => totalRows++);
    return totalRows;
  };

  const [selected, setSelected] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);

  const getAllSelectedNodes = () => {
    const nodes = [];
    props.api.forEachNode((node) => {
      if (node.isSelected()) {
        nodes.push(node);
      }
    });

    return nodes;
  };

  const isNonEditablePresent = () => {
    const nodes = [];
    props.api.forEachNode((node) => {
      if (node.data && !node.data.editable) {
        nodes.push(node);
      }
    });
    return nodes.length;
  };

  const allNodesInView = getTotalRowCount();
  const selectedNodes = getAllSelectedNodes().length + isNonEditablePresent();

  useEffect(() => {
    if (allNodesInView > 0) {
      if (allNodesInView === selectedNodes) {
        setSelected(true);
        setIndeterminate(false);
      } else if (selectedNodes > 0) {
        setSelected(false);
        setIndeterminate(true);
      } else {
        setSelected(false);
        setIndeterminate(false);
      }
    }
  }, [allNodesInView, selectedNodes]);

  const handleClick = (event) => {
    setSelected(event.target.checked);
    setIndeterminate(false);
    props.api.forEachNode((node) => {
      if (node.data.editable) {
        node.setSelected(event.target.checked);
      }
    });
    props.api.refreshCells({ force: true, columns: ['select'] });
  };

  return (
    <Checkbox
      checked={selected}
      onClick={handleClick}
      indeterminate={indeterminate}
    />
  );
};

CustomHeaderCheckboxRenderer.propTypes = {
  api: PropTypes.object,
};

export default CustomHeaderCheckboxRenderer;
