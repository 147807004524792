import { message, Row, Col, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Cookie from 'js-cookie';
import store from '../../../../redux/store';
import service from '../../../../service/Service';
import {
  getLastRowIndex,
  getUUID,
  isObject,
  getEmail,
  gaPageviewTimingTitle,
  getEpochTime,
} from '../../../../helpers/utils';
import {
  exportDTo,
  getMassDownloadGridParams,
} from '../../../../helpers/export';
import selectColumn from 'modules/pc/smi/components/SelectColObj';
import { setAgEditCount } from 'modules/pc/om/helpers/components/component-utils';

const SMI_PULL_COLUMNS = 'pc/smi/SMI_PULL_COLUMNS';
const SMI_PULL_COLUMNS_SUCCESS = 'pc/smi/SMI_PULL_COLUMNS_SUCCESS';
const SMI_PULL_COLUMNS_FAIL = 'pc/smi/SMI_PULL_COLUMNS_FAIL';
const SMI_PULL_ROWS_INFO = 'pc/smi/SMI_PULL_ROWS_INFO';

const SMI_PULL_HISTORY_COLUMNS = 'pc/smi/SMI_PULL_HISTORY_COLUMNS';
const SMI_PULL_HISTORY_COLUMNS_SUCCESS =
  'pc/smi/SMI_PULL_HISTORY_COLUMNS_SUCCESS';
const SMI_PULL_HISTORY_COLUMNS_FAIL = 'pc/smi/SMI_PULL_HISTORY_COLUMNS_FAIL';
const SMI_PULL_HISTORY_ROWS_INFO = 'pc/smi/SMI_PULL_HISTORY_ROWS_INFO';

const DESTROY_SMI_BY_KEY = 'pc/smi/DESTROY_SMI_BY_KEY';

const SET_FOCUSED_CELL_EDITOR = 'pc/smi/SET_FOCUSED_CELL_EDITOR';

const RESET_HEADER_CHECKBOX_INITIAL = 'pc/smi/RESET_HEADER_CHECKBOX_INITIAL';
const RESET_HEADER_CHECKBOX_SUCCESS = 'pc/smi/RESET_HEADER_CHECKBOX_SUCCESS';

const SET_PICKER_INFO_SMI = 'pc/smi/SET_PICKER_INFO_SMI';

const initialState = {
  pulls: {},
  history: {},
};

export default function reducer(state = initialState, action = null) {
  switch (action.type) {
    case SMI_PULL_COLUMNS:
      return {
        ...state,
        pulls: {
          ...state.pulls,
          [action.tabKey]: { ...state.pulls[action.tabKey], loading: true },
        },
      };
    case SMI_PULL_COLUMNS_SUCCESS:
      return {
        ...state,
        pulls: {
          ...state.pulls,
          [action.tabKey]: {
            ...state.pulls[action.tabKey],
            loading: false,
            ...action.payload,
          },
        },
      };
    case SMI_PULL_COLUMNS_FAIL:
      return {
        ...state,
        pulls: {
          ...state.pulls,
          [action.tabKey]: {
            ...state.pulls[action.tabKey],
            loading: false,
            error: action.payload,
          },
        },
      };
    case SMI_PULL_ROWS_INFO:
      return {
        ...state,
        pulls: {
          ...state.pulls,
          [action.tabKey]: {
            ...state.pulls[action.tabKey],
            rowsInfo: action.payload,
          },
        },
      };
    case SMI_PULL_HISTORY_COLUMNS:
      return {
        ...state,
        history: {
          ...state.history,
          [action.tabKey]: { ...state.history[action.tabKey], loading: true },
        },
      };
    case SMI_PULL_HISTORY_COLUMNS_SUCCESS:
      return {
        ...state,
        history: {
          ...state.history,
          [action.tabKey]: {
            ...state.history[action.tabKey],
            loading: false,
            ...action.payload,
          },
        },
      };
    case SMI_PULL_HISTORY_COLUMNS_FAIL:
      return {
        ...state,
        history: {
          ...state.history,
          [action.tabKey]: {
            ...state.history[action.tabKey],
            loading: false,
            error: action.payload,
          },
        },
      };
    case SMI_PULL_HISTORY_ROWS_INFO:
      return {
        ...state,
        history: {
          ...state.history,
          [action.tabKey]: {
            ...state.history[action.tabKey],
            rowsInfo: action.payload,
          },
        },
      };
    case DESTROY_SMI_BY_KEY:
      delete state[action.view][action.tabKey];
      return state;
    case SET_FOCUSED_CELL_EDITOR:
      return {
        ...state,
        pulls: {
          ...state.pulls,
          [action.tabKey]: {
            ...state.pulls[action.tabKey],
            focusedCell: {
              colId: action.colId,
              rowIndex: action.rowIndex,
            },
          },
        },
      };
    case RESET_HEADER_CHECKBOX_INITIAL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          resetHeaderCheckbox: false,
        },
      };
    case RESET_HEADER_CHECKBOX_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          resetHeaderCheckbox: action.reset,
        },
      };
    case SET_PICKER_INFO_SMI:
      return {
        ...state,
        pulls: {
          ...state.pulls,
          [action.tabKey]: {
            ...state['pulls'][action.tabKey],
            datePicker: {
              ...action.payload,
            },
          },
        },
      };
    default:
      return state;
  }
}

const getQsProps = (tabKey) => {
  const qsState = store.getState().pc.search.quickSearch;
  const tabData = qsState[tabKey] || {};

  const stringify = (val = '') => {
    if (typeof val !== 'string') {
      const filteredVal = val.filter((data) => data !== 'all');
      return filteredVal.join();
    } else {
      return val;
    }
  };

  return {
    customer_plant: stringify(tabData.customerPlant),
    supplier_name: stringify(tabData.supplierName),
    supplier_code: stringify(tabData.supplierCode),
    supplier_key: stringify(tabData.supplierDepot),
    site_name: stringify(tabData.supplierSite),
    agility_type: stringify(tabData.agilityType),
    search_term: tabData.searchTerm,
  };
};

function getSmiPullColumns(tabKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: SMI_PULL_COLUMNS, tabKey });
    const currentTime = getEpochTime();
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'AG_GRID_COLUMNS',
            view: `SMI_PULLS`,
            details: {
              email: getEmail(),
            },
          },
          getState
        )
      )
      .then((response) => {
        gaPageviewTimingTitle(
          `/CDS/GET_SMI_PULLS_AG_GRID_COLUMNS`,
          currentTime
        );
        const { data } = response;
        if (data && data.statusCode === '200') {
          data.result.columnDefs.unshift({
            ...selectColumn(tabKey),
            width: 44,
          });

          const { columnDefs, ...configs } = data.result;
          let dateFields = [];
          const columnDefsModified = columnDefs.map((columnDef) => {
            if (columnDef.type === 'date') {
              dateFields = [...dateFields, columnDef.colId];
              return {
                ...columnDef,
                floatingFilterComponent: 'agCustomDateFloatingFilter',
                filter: 'agCustomDateFilter',
              };
            }
            return columnDef;
          });
          const configDetails = {
            ...configs,
            columnDefs: columnDefsModified,
            dateFields,
          };

          dispatch({
            type: SMI_PULL_COLUMNS_SUCCESS,
            payload: configDetails,
            tabKey,
          });
        } else {
          dispatch({
            type: SMI_PULL_COLUMNS_FAIL,
            payload: 'Error in loading smi pulls!',
            tabKey,
          });
        }
      });
  };
}

function getSmiPullRows(tabKey) {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      const currentTime = getEpochTime();
      return api
        .post(
          'pc',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: `SMI_PULLS`,
              details: {
                email: getEmail(),
                ...getQsProps(tabKey),
              },
              gridParams: { ...params.request },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(`/CDS/GET_SMI_PULLS_AG_GRID_ROWS`, currentTime);

          const { data } = response;
          if (data && data.statusCode === '200') {
            const result = data.result;
            params.success({
              rowData: result.rowData.map((row) => ({
                originalValues: row,
                ...row,
              })),
              rowCount: getLastRowIndex(params.request, result.rowData),
            });
            const endRow =
              params.request.endRow > result.lastRow
                ? result.lastRow
                : params.request.endRow;
            store.dispatch({
              type: SMI_PULL_ROWS_INFO,
              payload: { lastRow: result.lastRow, endRow },
              tabKey,
            });
            // Hide overlay loading on success
            params.api.hideOverlay();
            params.api.deselectAll();
            if (!result.rowData.length) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch((_error) => {
          params.fail();
        });
    },
  };
}

function getSmiPullHeaderDetails(nodeData, cb) {
  return (_dispatch, getState, { api, formatParams }) => {
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'VIEW_SMI_HEADER',
            view: 'SMI_PULLS',
            details: {
              email: getEmail(),
              partname: nodeData[`part`],
              supplierkey: nodeData[`supplier_key`],
              customercode: nodeData[`customer_code`],
              customerplant: nodeData[`customer_plant`],
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (isObject(data)) {
          cb(data);
        }
      });
  };
}

function getSmiHistoryColumns(tabKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: SMI_PULL_HISTORY_COLUMNS, tabKey });
    const currentTime = getEpochTime();
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'AG_GRID_COLUMNS',
            view: `SMI_PULLS_HISTORY`,
            details: {
              email: getEmail(),
            },
          },
          getState
        )
      )
      .then((response) => {
        gaPageviewTimingTitle(
          `/CDS/GET_SMI_PULLS_HISTORY_AG_GRID_COLUMNS`,
          currentTime
        );

        const { data } = response;
        if (data && data.statusCode === '200') {
          const { columnDefs, ...configs } = data.result;
          let dateFields = [];
          const columnDefsModified = columnDefs.map((columnDef) => {
            if (columnDef.type === 'date') {
              dateFields = [...dateFields, columnDef.colId];
              return {
                ...columnDef,
                floatingFilterComponent: 'agCustomDateFloatingFilter',
                filter: 'agCustomDateFilter',
              };
            }
            return columnDef;
          });
          const configDetails = {
            ...configs,
            columnDefs: columnDefsModified,
            dateFields,
          };

          dispatch({
            type: SMI_PULL_HISTORY_COLUMNS_SUCCESS,
            payload: configDetails,
            tabKey,
          });
        } else {
          dispatch({
            type: SMI_PULL_HISTORY_COLUMNS_FAIL,
            payload: 'Error in loading smi pulls!',
            tabKey,
          });
        }
      });
  };
}

function getSmiHistoryRows(tabKey) {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      const currentTime = getEpochTime();
      return api
        .post(
          'pc',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: `SMI_PULLS_HISTORY`,
              details: {
                email: getEmail(),
                ...getQsProps(tabKey),
              },
              gridParams: { ...params.request },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(
            `/CDS/GET_SMI_PULLS_HISTORY_AG_GRID_ROWS`,
            currentTime
          );

          const { data } = response;
          if (data && data.statusCode === '200') {
            const result = data.result;
            params.success({
              rowData: result.rowData,
              rowCount: getLastRowIndex(params.request, result.rowData),
            });
            const endRow =
              params.request.endRow > result.lastRow
                ? result.lastRow
                : params.request.endRow;
            store.dispatch({
              type: SMI_PULL_HISTORY_ROWS_INFO,
              payload: { lastRow: result.lastRow, endRow },
              tabKey,
            });
            // Hide overlay loading on success
            params.api.hideOverlay();
            if (!result.rowData.length) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch((_error) => {
          params.fail();
        });
    },
  };
}

const isNull = (val) => (val == null || val == '' ? null : val);

function prepareAndSubmitData(nodes, gridApi, t, tabKey) {
  if (nodes.length) {
    let asnParts = [];

    nodes.map(({ data }) => {
      asnParts.push({
        customer_plant: data.customer_plant,
        supplier_key: data.supplier_key,
        part: data.part,
        mpn: data.mpn,
        uom: data.uom,
        po_number: data.bpo_number,
        po_release: data.po_release,
        shipment_no: isNull(data.shipment_no),
        qty: isNull(data.asn_ship_qty),
        ship_date: data.ship_date,
        expt_receipt_date: data.expt_receipt_date,
        line_number: isNull(data.shipment_line_number),
        carrier: isNull(data.carrier),
        service_level: isNull(data.service_level),
        ship_method: isNull(data.ship_method),
        packing_slip: isNull(data.packing_slip),
        waybill_number: isNull(data.waybill_number),
        net_weight: isNull(data.net_weight),
        weight_units: isNull(data.weight_units),
      });
    });

    return (dispatch, _getState, { api }) => {
      return api
        .post('pcsubmitaction', {
          TXN: {
            Header: {
              customer: 'SANM',
              excel: 'NO',
              requestor: Cookie.get('email'),
              source: 'PORTAL',
              txn_type: '856SMI',
              uuid: getUUID(),
            },
            ASNPartArray: asnParts,
          },
        })
        .then((response) => {
          const { data } = response;
          if (data.TXN[0].return_msg == 'SUCCESS') {
            message.success(t('data_updated_successfully'));
            //Refresh grid
            setAgEditCount({ tabKey, reset: true });
            nodes.map((node) => {
              node.setSelected(false);
              node.updateData({
                ...node.data,
                isEditing: false,
                errors: {},
                hasError: false,
              });
            });
            gridApi.refreshServerSide({ purge: true });
            gridApi.refreshCells({ force: true });
            gridApi.refreshHeader();
          } else {
            const failureResponse = data.TXN[0].ASNPartArray;
            Modal.error({
              title: t('failed_transactions'),
              icon: <ExclamationCircleOutlined />,
              width: '52%',
              content: (
                <div
                  style={{
                    maxHeight: 250,
                    minHeight: 150,
                    overflowY: 'auto',
                  }}>
                  <div
                    style={{
                      borderBottom: '1px solid #CCC',
                      padding: 5,
                      fontSize: 12,
                      fontWeight: 'bold',
                    }}>
                    <Row>
                      <Col style={{ textAlign: 'center' }} span={5} order={1}>
                        Customer Plant
                      </Col>
                      <Col style={{ textAlign: 'center' }} span={4} order={2}>
                        Supplier Key
                      </Col>
                      <Col style={{ textAlign: 'center' }} span={7} order={3}>
                        Part
                      </Col>
                      <Col style={{ textAlign: 'center' }} span={8} order={4}>
                        Error Message
                      </Col>
                    </Row>
                  </div>
                  {failureResponse[0].map((item) => (
                    <div
                      key={getUUID()}
                      style={{
                        borderBottom: '1px solid #CCC',
                        padding: 5,
                        fontSize: 10,
                      }}>
                      <Row>
                        <Col style={{ textAlign: 'center' }} span={5} order={1}>
                          {item.customer_plant}
                        </Col>
                        <Col style={{ textAlign: 'center' }} span={4} order={2}>
                          {item.supplier_key}
                        </Col>
                        <Col style={{ textAlign: 'center' }} span={7} order={3}>
                          {item.part}
                        </Col>
                        <Col
                          style={{
                            textAlign: 'center',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                          span={8}
                          order={4}>
                          <span title={item.error_message}>
                            {item.error_message}
                          </span>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </div>
              ),
            });
          }
          return response
        });
    };
  }
}

const commentUpdate = (txnData, t) => {
  return (_dispatch, _getState, { api }) => {
    return api
      .post('pcsubmitaction', {
        TXN: {
          Header: {
            customer: 'SANM',
            requestor: Cookie.get('email'),
            source: 'PORTAL',
            txn_type: 'COMMENT_UPDATE',
            uuid: getUUID(),
          },
          PartsArray: txnData,
        },
      })
      .then((response) => {
        if (response.data?.TXN) {
          let txn = response.data.TXN[0];
          if (txn.return_msg === 'SUCCESS') {
            message.success(t('data_updated_successfully'));
          } else {
            message.error(t('something_went_wrong'));
          }
        }
      });
  };
};

function getMassDownloadReport({
  gridApi,
  exportType,
  totalRows,
  cb,
  columnApi,
  fileName,
  view,
  tabKey,
  columnsExclude,
}) {
  return (_dispatch, getState, { api, formatParams }) => {
    if (totalRows >= process.env.REACT_APP_MASS_DOWNLOAD_ENABLE_LIMIT) {
      return api
        .post(
          'pc',
          formatParams(
            {
              type: 'MASS_DOWNLOAD',
              view: view,
              exportType,
              totalRows,
              email: getEmail(),
              details: {
                email: getEmail(),
                ...getQsProps(tabKey),
              },
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
              },
            },
            getState
          )
        )
        .then((response) => {
          cb(true);
          return response;
        })
        .catch((error) => {
          cb(false);
          return error;
        });
    } else {
      const currentTime = getEpochTime();
      return api
        .post(
          'pc',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: view,
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
                startRow: 0,
                endRow: totalRows,
              },
              details: {
                email: getEmail(),
                ...getQsProps(tabKey),
              },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(
            `/CDS/GET_${view}_MASS_DOWNLOAD_REPORT`,
            currentTime
          );
          const { data } = response;
          if (data) {
            const { result } = data;
            if (Object.keys(result).length) {
              exportDTo({
                columnApi,
                fileName: fileName,
                data: result.rowData,
                exportType,
                columnsExclude,
              }) && cb(true);
            }
          }

          return response;
        });
    }
  };
}

const destroySmiByKey = (tabKey, view) => {
  return (dispatch) => {
    dispatch({ type: DESTROY_SMI_BY_KEY, tabKey, view });
  };
};

const setFocusedCell = (colId, rowIndex, tabKey) => {
  return (dispatch) => {
    dispatch({ type: SET_FOCUSED_CELL_EDITOR, colId, rowIndex, tabKey });
  };
};

const resetHeaderCheckbox = (tabKey) => {
  return (dispatch) => {
    dispatch({ type: RESET_HEADER_CHECKBOX_INITIAL, tabKey: tabKey });
    setTimeout(() => {
      dispatch({
        type: RESET_HEADER_CHECKBOX_SUCCESS,
        tabKey: tabKey,
        reset: true,
      });
    }, 100);
  };
};

const setPickerInfo = (payload, tabKey) => {
  return (dispatch) => {
    dispatch({
      type: SET_PICKER_INFO_SMI,
      payload,
      tabKey,
    });
  };
};

export {
  getSmiPullColumns,
  getSmiPullRows,
  getSmiHistoryColumns,
  getSmiHistoryRows,
  destroySmiByKey,
  prepareAndSubmitData,
  getMassDownloadReport,
  getSmiPullHeaderDetails,
  commentUpdate,
  setFocusedCell,
  resetHeaderCheckbox,
  setPickerInfo,
};
